import { Currency } from '@publica/lookups'
import { renderValue } from '@publica/render'
import { useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { useRenderedQuantity, useRenderedQuantityAsPercentage } from '../../lib'

type LedgerQuantityProps = {
    quantity: number | undefined
    format?: 'percentage' | { format: 'amount'; currency?: Currency }
}

export const LedgerQuantity: FC<LedgerQuantityProps> = ({ quantity, format }) => {
    if (quantity === undefined) {
        return null
    }

    if (format === 'percentage') {
        return <LedgerQuantityAsPercentage quantity={quantity} />
    } else {
        const currency = format?.format === 'amount' ? format.currency : undefined
        return <LedgerQuantityAsAmount quantity={quantity} currency={currency} />
    }
}

type LedgerQuantityAsAmountProps = {
    quantity: number
    currency?: Currency
}

const LedgerQuantityAsAmount: FC<LedgerQuantityAsAmountProps> = ({ quantity, currency }) => {
    const formattedAmount = useRenderedQuantity(quantity)
    const locale = useCurrentLocale()

    if (currency === undefined) {
        return <>{formattedAmount}</>
    }

    return renderValue({
        type: 'FloatValue',
        floatValue: quantity,
        options: {
            locale,
            precision: 2,
            numberFormat: 'NUMBER',
            currency,
        },
        context: 'web',
    })
}

type LedgerQuantityAsPercentageProps = {
    quantity: number
}

const LedgerQuantityAsPercentage: FC<LedgerQuantityAsPercentageProps> = ({ quantity }) => {
    const formattedPercentage = useRenderedQuantityAsPercentage(quantity)
    return <>{formattedPercentage}</>
}
