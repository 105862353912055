import { ExpandableConfig } from 'antd/lib/table/interface'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { LocalizedString } from '@publica/locales'
import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'

import { LedgerCurrencyAmount } from '../LedgerCurrencyAmount'
import { LedgerInvestmentHistory } from '../LedgerInvestmentHistory'
import { LedgerQuantity } from '../LedgerQuantity'
import { NotApplicable } from '../NotApplicable'

type LedgerInvestmentIndicators = {
    totalInvested: number
    totalInvestedCash: number
    totalInvestedEquity: number
}

type LedgerInvestment = LedgerInvestmentIndicators & { id: string; occurredAt: DateTime }

type LedgerBalance = {
    shareCapital: number
    quantity?: number
    investmentsByDate: LedgerInvestment[]
} & LedgerInvestmentIndicators

type LedgerAssetTypeBalance = LedgerBalance & {
    assetType: {
        id: string
        name: LocalizedString
        parValue: number
    }
}

type LedgerInvestmentTableProps = {
    balance: LedgerBalance & { id: string; balanceByAssetType: LedgerAssetTypeBalance[] }
}

export const LedgerInvestmentTable: FC<LedgerInvestmentTableProps> = ({ balance }) => {
    const { t } = useLedgerInvestmentTableTranslation()
    const resolveLocalizedString = useLocalizedStringResolver()

    const rows = useMemo(
        (): LedgerInvestmentTableRow[] => [
            {
                ...balance,
                name: t('total'),
            },
            ...balance.balanceByAssetType
                .map(balanceByAssetType => ({
                    id: balanceByAssetType.assetType.id,
                    quantity: balanceByAssetType.quantity,
                    name: resolveLocalizedString(balanceByAssetType.assetType.name),
                    shareCapital: balanceByAssetType.shareCapital,
                    parValue: balanceByAssetType.assetType.parValue,
                    totalInvested: balanceByAssetType.totalInvested,
                    totalInvestedCash: balanceByAssetType.totalInvestedCash,
                    totalInvestedEquity: balanceByAssetType.totalInvestedEquity,
                    investmentsByDate: balanceByAssetType.investmentsByDate,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
        ],
        [balance, resolveLocalizedString, t]
    )

    const columns = useMemo(
        (): FilterColumnType<LedgerInvestmentTableRow>[] => [
            {
                key: 'name',
                render: (_, row) => row.name,
            },
            ...(
                [
                    'quantity',
                    'totalInvested',
                    'totalInvestedCash',
                    'totalInvestedEquity',
                    'parValue',
                    'shareCapital',
                ] as const
            ).map(
                (key): FilterColumnType<LedgerInvestmentTableRow> => ({
                    title: t(key),
                    key,
                    align: 'end',
                    render: (_, row) => {
                        if (row[key] === undefined) {
                            return <NotApplicable />
                        }
                        if (key === 'quantity') {
                            return <LedgerQuantity quantity={row[key]} />
                        }
                        return <LedgerCurrencyAmount quantity={row[key]} />
                    },
                })
            ),
        ],
        [t]
    )

    const expandable = useMemo(
        (): ExpandableConfig<LedgerInvestmentTableRow> => ({
            expandedRowRender: row => <LedgerInvestmentHistory investments={row.investmentsByDate} />,
            rowExpandable: row => row.investmentsByDate.length > 0,
        }),
        []
    )

    return <FilterTable<LedgerInvestmentTableRow> dataSource={rows} expandable={expandable} columns={columns} />
}

const useLedgerInvestmentTableTranslation = createUseTranslation({
    FR: {
        shareCapital: 'Capital Social',
        total: 'Total',
        totalInvested: 'Montant total investi',
        totalInvestedCash: 'Dont montant total investi par apport en numéraire',
        totalInvestedEquity: 'Dont montant total investi par apport en nature',
        parValue: 'Valeur nominale unitaire',
        quantity: 'Nombre de titres',
    },
    EN: {
        shareCapital: 'Share Capital',
        total: 'Total',
        totalInvested: 'Total invested amount',
        totalInvestedCash: 'Of which invested in cash',
        totalInvestedEquity: 'Of which invested in equity',
        parValue: 'Unit par value',
        quantity: 'Quantity of shares',
    },
})

type LedgerInvestmentTableRow = {
    id: string
    name: string
    quantity?: number
    parValue?: number
    shareCapital: number
    totalInvested: number
    totalInvestedCash: number
    totalInvestedEquity: number
    investmentsByDate: LedgerInvestment[]
}
