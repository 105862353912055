import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form, FormInstance } from 'antd'
import { useCallback, useMemo } from 'react'

import { EquityTransferLedgerTransactionCreateInput, LedgerTransactionsCreateInput } from '@publica/api-graphql'
import { FC } from '@publica/ui-common-utils'
import { ControlledForm, FormRules, useCommonRules, useConnectControlledForm } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import {
    CashTransferLedgerTransactionCreateInput,
    LedgerAccountType,
    useCreateLedgerTransactionsMutation,
} from '../../../../../../data'
import { LedgerAccountSearch, QuantityInput } from '../../../../../components'
import {
    TransactionContextForm,
    TransactionContextFormValues,
    TransferableAsset,
    TransferableAssetForm,
    unpackTransferableAsset,
    useDistinctLedgerAccountValidators,
    useTransactionFormTranslations,
} from '../components'

type TransferFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, TransferFormValues>
    refetchQueries?: InternalRefetchQueriesInclude
    type: 'TRANSFER_CASH' | 'TRANSFER_EQUITY'
}

type LedgerAccount = {
    id: string
}

type TransferFormValues = TransactionContextFormValues & {
    quantity?: number
    unitValue?: number
    debitAccount?: LedgerAccount
    creditAccount?: LedgerAccount
    transfer?: TransferableAsset
}

export const TransferForm: FC<TransferFormProps> = ({ ledgerId, form, refetchQueries, type }) => {
    const [createLedgerTransactionsMutation, { loading }] = useCreateLedgerTransactionsMutation()

    const submit = useCallback(
        async (values: TransferFormValues): Promise<string | undefined> => {
            const { occurredAt, comment, unitValue, debitAccount, creditAccount, transfer, quantity } = values

            assert.defined(quantity)
            assert.defined(occurredAt)
            assert.defined(unitValue)
            assert.defined(debitAccount)
            assert.defined(creditAccount)

            const debitAccountId = debitAccount.id
            const creditAccountId = creditAccount.id

            const { holdershipType, dismembermentId, assetTypeId } = unpackTransferableAsset(transfer)

            const transactions: LedgerTransactionsCreateInput = {}

            const transaction: CashTransferLedgerTransactionCreateInput | EquityTransferLedgerTransactionCreateInput = {
                sequence: 0,
                holdershipType,
                occurredAt,
                comment,
                creditAccountId,
                assetTypeId,
                quantity,
                debitAccountId,
                unitValue,
                dismembermentId,
            }

            switch (type) {
                case 'TRANSFER_CASH':
                    transactions.cashTransfers = [transaction]
                    break
                case 'TRANSFER_EQUITY':
                    transactions.equityTransfers = [transaction]
                    break
            }

            return createLedgerTransactionsMutation({
                variables: {
                    ledgerId,
                    transactions,
                },
                refetchQueries,
            }).then(transactions => transactions.data?.createLedgerTransactions[0]?.id)
        },
        [createLedgerTransactionsMutation, ledgerId, refetchQueries, type]
    )

    useConnectControlledForm(form, submit, loading)

    return <TransferTransactionForm ledgerId={ledgerId} form={form.form} />
}

type TransferTransactionFormProps = {
    ledgerId: string
    form: FormInstance<TransferFormValues>
}

const TransferTransactionForm: FC<TransferTransactionFormProps> = ({ ledgerId, form }) => {
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()

    const [debitAccountValidator, creditAccountValidator] = useDistinctLedgerAccountValidators<TransferFormValues>(
        'debitAccount',
        'creditAccount'
    )

    const validation = useMemo<FormRules<TransferFormValues>>(
        () => ({
            debitAccount: [...rules.required, debitAccountValidator],
            creditAccount: [...rules.required, creditAccountValidator],
            unitValue: rules.required,
        }),
        [creditAccountValidator, debitAccountValidator, rules.required]
    )

    const debitAccount = Form.useWatch('debitAccount', form)
    const transfer = Form.useWatch('transfer', form)

    const creditAccountDisabled = transfer === undefined
    const creditAccountTypes = useMemo(
        () => transfer?.assetType?.eligibleAccountTypes ?? [],
        [transfer?.assetType?.eligibleAccountTypes]
    )

    return (
        <Form form={form} layout="vertical">
            <TransactionContextForm ledgerId={ledgerId}>
                <Form.Item name="debitAccount" label={t('debitAccount')} rules={validation.debitAccount} hasFeedback>
                    <LedgerAccountSearch ledgerAccountTypes={transferLedgerAccountType} ledgerId={ledgerId} />
                </Form.Item>
                <TransferableAssetForm debitAccountId={debitAccount?.id} form={form} />
                <Form.Item name="creditAccount" label={t('creditAccount')} rules={validation.creditAccount} hasFeedback>
                    <LedgerAccountSearch
                        ledgerAccountTypes={creditAccountTypes}
                        ledgerId={ledgerId}
                        disabled={creditAccountDisabled}
                    />
                </Form.Item>
                <Form.Item name="unitValue" label={t('unitValue')} rules={validation.unitValue} hasFeedback>
                    <QuantityInput />
                </Form.Item>
            </TransactionContextForm>
        </Form>
    )
}

const transferLedgerAccountType: LedgerAccountType[] = ['PEA_PME', 'PEA', 'STANDARD']
