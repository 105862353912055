import { ButtonProps } from 'antd'
import { ColumnType } from 'antd/lib/table'
import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'

import * as graphql from '@publica/api-graphql'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable, LinkButton, icons } from '@publica/ui-web-components'

import { LedgerName } from '../../../../components'

type Ledger = Pick<graphql.Ledger, 'id' | 'name'> & {
    company: {
        identifier: string
    }
}

export type LedgerTableProps = {
    loading?: boolean
    ledgers: Ledger[]
}

const useLedgerTableTranslation = createUseTranslation({
    FR: {
        ledger: 'Registre',
        actions: 'Actions',
        view: 'Consulter',
    },
    EN: {
        ledger: 'Ledger',
        actions: 'Actions',
        view: 'View',
    },
})

export const LedgerTable: FC<LedgerTableProps> = ({ loading = false, ledgers }) => {
    const sortedLedgers = useMemo(() => sortBy(ledgers, le => le.name), [ledgers])
    const { t } = useLedgerTableTranslation()

    const columns = useMemo<(FilterColumnType<Ledger> | ColumnType<Ledger>)[]>(
        () => [
            {
                title: t('ledger'),
                render: (_, ledger) => <LedgerGoTo ledger={ledger} />,
                filterValue: ledger => ledger.name,
            },
            {
                title: t('actions'),
                align: 'center',
                width: 180,
                render: (_, ledger) => <LedgerGoTo ledger={ledger} title={t('view')} type="primary" />,
            },
        ],
        [t]
    )

    return <FilterTable<Ledger> dataSource={sortedLedgers} rowKey="id" loading={loading} columns={columns} />
}

type LedgerGoToProps = {
    ledger: Ledger
    title?: string
    type?: ButtonProps['type']
}

const LedgerGoTo: FC<LedgerGoToProps> = ({ type, ledger, title }) => {
    const text = title ?? <LedgerName ledger={ledger} />

    return (
        <LinkButton to={ledger.id} type={type ?? 'link'} icon={type === 'primary' ? icons.View : undefined}>
            {text}
        </LinkButton>
    )
}
