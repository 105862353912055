import { Tag } from 'antd'
import { ColumnType } from 'antd/lib/table'
import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'

import { LocalizedString } from '@publica/locales'
import { ledgerAccountTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterTable } from '@publica/ui-web-components'

import { LedgerAccountType } from '../../../../../data'
import { LedgerCurrencyAmount } from '../../../../components'

type LedgerAssetType = {
    id: string
    name: LocalizedString
    parValue: number
    eligibleAccountTypes: LedgerAccountType[]
}

type LedgerAssetTypeTableProps = {
    loading?: boolean
    ledgerAssetTypes: LedgerAssetType[]
}

export const LedgerAssetTypeTable: FC<LedgerAssetTypeTableProps> = ({ ledgerAssetTypes, loading = false }) => {
    const { t } = useLedgerAssetTypeTranslation()
    const locale = useCurrentLocale()
    const resolveLocalizedString = useLocalizedStringResolver()

    const columns = useMemo<ColumnType<LedgerAssetType>[]>(
        () => [
            {
                title: t('name'),
                render: (_, ledgerAssetType) => resolveLocalizedString(ledgerAssetType.name),
            },
            {
                title: t('parValue'),
                align: 'end',
                render: (_, ledgerAssetType) => <LedgerCurrencyAmount quantity={ledgerAssetType.parValue} />,
            },
            {
                title: t('eligibleAccountTypes'),
                render: (_, ledgerAssetTypes) =>
                    ledgerAssetTypes.eligibleAccountTypes
                        .filter(type => type !== 'PLEDGE')
                        .map((type, idx) => (
                            <Tag key={idx}>{ledgerAccountTypeLookup.labelForKeyAndLocale(type, locale)}</Tag>
                        )),
            },
        ],
        [locale, resolveLocalizedString, t]
    )
    const sortedLedgerAssetTypes = useMemo(
        () => sortBy(ledgerAssetTypes, assetType => assetType.name),
        [ledgerAssetTypes]
    )

    return <FilterTable columns={columns} dataSource={sortedLedgerAssetTypes} loading={loading} />
}

const useLedgerAssetTypeTranslation = createUseTranslation({
    FR: {
        assetTypes: 'Actifs',
        name: 'Actif',
        parValue: 'Valeur nominale',
        eligibleAccountTypes: 'Comptes éligibles',
    },
    EN: {
        assetTypes: 'Asset types',
        name: 'Asset type',
        parValue: 'Par value',
        eligibleAccountTypes: 'Eligible account types',
    },
})
