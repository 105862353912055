import { Select as BaseSelect } from 'antd'
import { BaseOptionType, SelectProps as BaseSelectProps, DefaultOptionType } from 'antd/es/select'
import isArray from 'lodash/isArray'
import { useCallback, useEffect, useMemo, useState } from 'react'

type SelectProps<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ValueType = any,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> = BaseSelectProps<ValueType, OptionType>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Select<ValueType = any, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
    props: SelectProps<ValueType, OptionType>
) {
    const { options, value, onChange, ...otherProps } = props

    const [currentOption, setCurrentOption] = useState<OptionType | OptionType[] | undefined>()

    useEffect(() => {
        const currentOption = options?.find(opt => opt.value === value)

        setCurrentOption(currentOption)
        if (value !== undefined && currentOption === undefined) {
            onChange?.(undefined as ValueType, undefined)
        }
    }, [onChange, options, value])

    const _onChange = useCallback(
        (value: ValueType, option: OptionType | OptionType[] | undefined) => {
            setCurrentOption(option)
            onChange?.(value, option)
        },
        [onChange]
    )

    const currentValue = useMemo(
        () =>
            currentOption === undefined
                ? undefined
                : isArray(currentOption)
                  ? currentOption.map(opt => opt.value)
                  : currentOption.value,
        [currentOption]
    )

    return (
        <BaseSelect<ValueType, OptionType>
            {...otherProps}
            options={options}
            onChange={_onChange}
            value={currentValue}
        />
    )
}
