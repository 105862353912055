import isArray from 'lodash/isArray'
import { useCallback } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { RemoteSelect } from '@publica/ui-web-components'

import { LegalEntityType, useGetLegalEntitiesByQueryLazyQuery } from '../../../data'

type LegalEntitySearchProps = {
    value?: LegalEntity
    onChange?: (value: LegalEntity | undefined) => void
    type?: LegalEntityType
    disabled?: boolean
}

export const LegalEntitySearch: FC<LegalEntitySearchProps> = ({ onChange, type, disabled }) => {
    const [getLegalEntitiesByQuery] = useGetLegalEntitiesByQueryLazyQuery()
    const { t } = useLegalEntitySearchTranslation()

    const searchLegalEntities = useCallback<(term: string) => Promise<LegalEntity[]>>(
        async (term: string) =>
            getLegalEntitiesByQuery({
                variables: {
                    name: term,
                    type,
                },
                fetchPolicy: 'no-cache',
            }).then(result => result.data?.legalEntitiesByQuery ?? []),
        [getLegalEntitiesByQuery, type]
    )

    const onChangeLegalEntity = useCallback<
        (value: unknown, option: LegalEntitySearchResult | LegalEntitySearchResult[] | undefined) => void
    >(
        (_, option) => {
            // We don't handle multiple values
            if (option === undefined) {
                onChange?.(undefined)
            } else if (!isArray(option)) {
                onChange?.(option.legalEntity)
            }
        },
        [onChange]
    )

    const fetchData = useCallback<(term: string) => Promise<LegalEntitySearchResult[]>>(
        async (term: string) =>
            searchLegalEntities(term).then(results =>
                results.map(legalEntity => ({ label: legalEntity.name, value: legalEntity.id, legalEntity }))
            ),
        [searchLegalEntities]
    )

    return (
        <RemoteSelect<LegalEntitySearchResult>
            placeholder={t('searchPlaceholder')}
            fetchData={fetchData}
            onChange={onChangeLegalEntity}
            disabled={disabled}
        />
    )
}

const useLegalEntitySearchTranslation = createUseTranslation({
    FR: {
        searchPlaceholder: 'Chercher entité légale...',
    },
    EN: {
        searchPlaceholder: 'Search for legal entity...',
    },
})

type LegalEntitySearchResult = {
    label: string
    value: string
    legalEntity: LegalEntity
}

type LegalEntity = {
    id: string
    name: string
}
