import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'
import { NotFound } from '@publica/ui-web-components'

import { LedgerIndex } from './LedgerIndex'
import { ShowLedger } from './ShowLedger'

const ledgerIndex = <LedgerIndex />
const showLedger = <ShowLedger />
const notFound = <NotFound />

export const Ledgers: FC = () => (
    <Routes>
        <Route path="ledgers/" element={ledgerIndex} />
        <Route path="ledgers/:ledgerId/*" element={showLedger} />
        <Route path="ledgers/*" element={notFound} />
    </Routes>
)
