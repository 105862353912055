import { Divider } from 'antd'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { VerticalSpacer } from '@publica/ui-web-components'
import { assert } from '@publica/utils'

import { useGetLedgerBalanceSuspenseQuery } from '../../../data'
import { LedgerInvestmentTable } from '../../components'

type LedgerSummaryProps = {
    ledger: {
        id: string
    }
}

export const LedgerSummary: FC<LedgerSummaryProps> = props => {
    const { data } = useGetLedgerBalanceSuspenseQuery({
        variables: {
            ledgerId: props.ledger.id,
        },
    })

    const { t } = useLedgerSummaryTranslations()

    const ledger = data.ledger
    assert.defined(ledger)

    return (
        <VerticalSpacer size={10}>
            <Divider>{t('capital')}</Divider>
            <LedgerInvestmentTable balance={ledger.balance} />
        </VerticalSpacer>
    )
}

const useLedgerSummaryTranslations = createUseTranslation({
    FR: {
        capital: 'Capital et investissements',
    },
    EN: {
        capital: 'Capital and investments',
    },
})
