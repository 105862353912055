import { Form } from 'antd'
import { FormInstance } from 'antd/lib'
import isArray from 'lodash/isArray'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { LocalizedString } from '@publica/locales'
import { ledgerHoldershipTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { Select } from '@publica/ui-web-components'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { humanDate } from '../../../../../../../../../../packages/ui/common/labels/src/lib/date'
import { LedgerAccountType, LedgerHoldershipType, useGetLedgerAccountLazyQuery } from '../../../../../../../data'
import { QuantityInput } from '../../../../../../components'
import { useQuantityRenderer } from '../../../../../../lib'
import { useQuantityValidator, useTransactionFormTranslations } from '../common'

export type TransferableAssetFormProps = {
    debitAccountId: string | undefined
    form: FormInstance<TransferableAssetFormValues>
    holdershipTypes?: LedgerHoldershipType[]
}

type TransferableAssetFormValues = {
    quantity?: number | undefined
    transfer?: TransferableAsset | undefined
}

type LedgerAssetType = {
    name: LocalizedString
    id: string
    eligibleAccountTypes: LedgerAccountType[]
}

export const TransferableAssetForm: FC<TransferableAssetFormProps> = ({ debitAccountId, form, holdershipTypes }) => {
    const { t } = useTransactionFormTranslations()
    const rules = useCommonRules()
    const quantityValidator = useQuantityValidator()

    const transferableAsset = Form.useWatch('transfer', form)

    useEffect(() => {
        if (transferableAsset !== undefined) {
            form.setFieldValue('quantity', transferableAsset.quantity ?? 0)
        }
    }, [transferableAsset, form])

    const validation = useMemo<FormRules<TransferableAssetFormValues>>(
        () => ({
            quantity: [
                ...rules.required,
                quantityValidator,
                ({ getFieldValue }) => ({
                    validator: async (_, quantity: number | undefined) => {
                        if (quantity === undefined) {
                            return
                        }

                        const transferableAsset = getFieldValue('transfer') as TransferableAsset | undefined

                        if (transferableAsset === undefined) {
                            return
                        }

                        return new Promise<void>((resolve, reject) => {
                            if (quantity > transferableAsset.quantity) {
                                reject(new Error(t('insufficientBalance')))
                            } else {
                                resolve()
                            }
                        })
                    },
                }),
            ],
            transfer: rules.required,
        }),
        [quantityValidator, rules.required, t]
    )

    const { loading, transferableAssets } = useTransferableAssetsForAccount(debitAccountId, holdershipTypes)

    const transferDisabled = debitAccountId === undefined || transferableAssets.length === 0
    const quantityDisabled = transferDisabled || transferableAsset === undefined

    return (
        <>
            <Form.Item name="transfer" label={t('balance')} rules={validation.transfer} hasFeedback>
                <TransferableAssetSelect
                    transferableAssets={transferableAssets}
                    loading={loading}
                    disabled={transferDisabled}
                />
            </Form.Item>
            <Form.Item name="quantity" label={t('quantity')} rules={validation.quantity} hasFeedback>
                <QuantityInput disabled={quantityDisabled} />
            </Form.Item>
        </>
    )
}

type TransferableAssetSelectOption = {
    label: string
    value: string
    transferableAsset: TransferableAsset
}

export type TransferableAsset = {
    quantity: number
    assetType: LedgerAssetType
    holdershipType: LedgerHoldershipType
    dismemberment?: {
        id: string
        transaction: {
            occurredAt: DateTime
        }
    }
}

type TransferableAssetsForAccount = { transferableAssets: TransferableAsset[]; loading: boolean }

const useTransferableAssetsForAccount = (
    accountId: string | undefined,
    holdershipTypes: LedgerHoldershipType[] = ledgerHoldershipTypeLookup.keys()
): TransferableAssetsForAccount => {
    const [getLedgerAccountLazyQuery, { loading, data }] = useGetLedgerAccountLazyQuery()
    const [transferableAssets, setTransferableAssets] = useState<TransferableAsset[]>([])

    useEffect(() => {
        if (accountId === undefined) {
            setTransferableAssets([])
            return
        }

        void getLedgerAccountLazyQuery({
            variables: {
                id: accountId,
            },
            fetchPolicy: 'no-cache',
        })
    }, [accountId, getLedgerAccountLazyQuery])

    useEffect(() => {
        setTransferableAssets(
            data?.ledgerAccount?.balance.balanceByAssetType.flatMap(assetTypeBalance => {
                const assetType = assetTypeBalance.assetType

                return assetTypeBalance.balanceByHoldership.all
                    .filter(
                        balanceByHoldership =>
                            balanceByHoldership.quantity > 0 && holdershipTypes.includes(balanceByHoldership.type)
                    )
                    .flatMap(balanceByHoldership => {
                        return balanceByHoldership.origins.map((origin): TransferableAsset => {
                            if (origin.__typename === 'LedgerFullOwnershipBalanceOrigin') {
                                return {
                                    quantity: origin.quantity,
                                    holdershipType: 'FULL_OWNERSHIP',
                                    assetType,
                                }
                            } else {
                                return {
                                    quantity: origin.quantity,
                                    holdershipType: origin.holdershipType,
                                    assetType,
                                    dismemberment: origin.dismemberment,
                                }
                            }
                        })
                    })
            }) ?? []
        )
    }, [data?.ledgerAccount?.balance.balanceByAssetType, holdershipTypes])

    return {
        transferableAssets,
        loading,
    }
}

export const unpackTransferableAsset = (transfer?: TransferableAsset) => {
    assert.defined(transfer)

    const { dismemberment, assetType, holdershipType } = transfer

    assert.defined(assetType)
    assert.defined(holdershipType)

    return {
        dismembermentId: dismemberment?.id,
        assetTypeId: assetType.id,
        holdershipType,
    }
}

type TransferableAssetLabelGenerator = (transferableAsset: TransferableAsset) => string

const useTransferableAssetLabelGenerator = (): TransferableAssetLabelGenerator => {
    const resolveLocalizedString = useLocalizedStringResolver()
    const renderer = useQuantityRenderer()
    const locale = useCurrentLocale()
    const { t } = useTransferableAssetLabelGeneratorTranslation()

    return useCallback(
        (transferableAsset: TransferableAsset): string => {
            const parts = [
                resolveLocalizedString(transferableAsset.assetType.name),
                renderer(transferableAsset.quantity),
            ]

            if (transferableAsset.dismemberment !== undefined) {
                const holdership = ledgerHoldershipTypeLookup.labelForKeyAndLocale(
                    transferableAsset.holdershipType,
                    locale
                )
                parts.push(holdership)
                parts.push(
                    t('dismembermentFrom', {
                        date: humanDate(transferableAsset.dismemberment.transaction.occurredAt, true),
                    })
                )
            }

            return parts.join(', ')
        },
        [locale, renderer, resolveLocalizedString, t]
    )
}

const useTransferableAssetLabelGeneratorTranslation = createUseTranslation({
    FR: {
        dismembermentFrom: `démembrement du {{date}}`,
    },
    EN: {
        dismembermentFrom: `dismemberment from {{date}}`,
    },
})

type TransferableAssetSelectProps = {
    transferableAssets: TransferableAsset[]
    disabled?: boolean
    loading?: boolean
    value?: TransferableAsset
    onChange?: (transferableAsset?: TransferableAsset) => void
}

const TransferableAssetSelect: FC<TransferableAssetSelectProps> = ({
    transferableAssets,
    disabled,
    loading,
    onChange,
    value,
}) => {
    const transferableAssetLabelGenerator = useTransferableAssetLabelGenerator()

    const options = useMemo(
        (): TransferableAssetSelectOption[] =>
            transferableAssets.map(transferableAsset => ({
                label: transferableAssetLabelGenerator(transferableAsset),
                value: transferableAssetTypeId(transferableAsset),
                transferableAsset,
            })),
        [transferableAssetLabelGenerator, transferableAssets]
    )

    const onSelect = useCallback<
        (value: unknown, option: TransferableAssetSelectOption | TransferableAssetSelectOption[] | undefined) => void
    >(
        (_, option) => {
            if (option === undefined) {
                onChange?.(undefined)
            } else if (!isArray(option)) {
                onChange?.(option.transferableAsset)
            }
        },
        [onChange]
    )

    const selectedValue = value === undefined ? undefined : transferableAssetTypeId(value)

    return <Select loading={loading} value={selectedValue} disabled={disabled} options={options} onChange={onSelect} />
}

const transferableAssetTypeId = (transferableAsset: TransferableAsset) =>
    `${transferableAsset.assetType.id}:${transferableAsset.holdershipType}:${transferableAsset.dismemberment?.id ?? 'NO_DISMEMBERMENT'}`
