import { TablePaginationConfig } from 'antd'
import { useMemo } from 'react'

import { FC, useAsyncCallback, usePaginationConfig } from '@publica/ui-common-utils'
import { DateRange } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { LedgerTransactionType, useGetLedgerTransactionsQuery } from '../../../../../data'
import { LedgerTransactionTable as BaseLedgerTransactionTable } from '../../../../components'
import { useLedgerContext } from '../../../../context'

type LedgerTransactionTableProps = {
    ledger: {
        id: string
    }
}

export const LedgerTransactionTable: FC<LedgerTransactionTableProps> = ({ ledger }) => {
    const { cursor } = useLedgerContext()

    const { data, fetchMore, loading } = useGetLedgerTransactionsQuery({
        variables: {
            ledgerId: ledger.id,
            filters: {
                cursor,
            },
            cursor,
        },
        pollInterval: usePollingRate(),
    })

    const transactions = useMemo(
        () => (data?.ledger?.transactions.edges ?? []).map(edge => edge.node),
        [data?.ledger?.transactions.edges]
    )

    const pagination = usePaginationConfig(data?.ledger?.transactions.pageInfo)

    const onChange = useAsyncCallback(
        async (pagination: TablePaginationConfig, filters: { type?: LedgerTransactionType[]; date?: [DateRange] }) => {
            const { pageSize, current } = pagination
            const { before, after } = filters.date?.[0] ?? {}

            await fetchMore({
                variables: {
                    page: Math.max((current ?? 1) - 1, 0),
                    pageSize,
                    filters: {
                        cursor,
                        type: filters.type,
                        occurredAt: {
                            before,
                            after,
                        },
                    },
                },
            })
        },
        [fetchMore, cursor]
    )

    return (
        <BaseLedgerTransactionTable
            transactions={transactions}
            loading={loading}
            pagination={pagination}
            onChange={onChange}
        />
    )
}
