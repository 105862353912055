import { Rule } from 'antd/lib/form'
import isNil from 'lodash/isNil'
import { useCallback, useMemo } from 'react'

import { Currency, currencySymbols } from '@publica/lookups'
import { renderValue } from '@publica/render'
import { TranslationsFor, createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'

import { useLedgerContext } from './context'

const defaultOptions = {
    length: 4,
    skipHash: false,
}

type FormatTransactionSequenceOptions = {
    length?: number
    skipHash?: boolean
}

export const formatTransactionSequence = (
    seq: number | null | undefined,
    options?: FormatTransactionSequenceOptions
): string | undefined => {
    const length = options?.length ?? defaultOptions.length
    const skipHash = options?.skipHash ?? defaultOptions.skipHash

    let repr: string

    if (isNil(seq)) {
        return undefined
    } else {
        const seqStr = seq.toString()
        repr = `${'0'.repeat(length - seqStr.length)}${seqStr}`
    }

    return skipHash ? repr : `#${repr}`
}

// export const useDistinctLedgerAssetTypeValidators = (
//     aFieldKey: string,
//     bFieldKey: string,
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     opts?: { aTransform?: (a: any) => { id: string } | undefined; bTransform?: (b: any) => { id: string } | undefined }
// ) => {
//     const { t } = useDistinctLedgerAssetTypeValidatorTranslation()

//     return useMemo<[Rule, Rule]>(
//         () => [
//             // NOTE: the field keys are _supposed_ to be inverted
//             createDistinctObjectValidator(bFieldKey, t('assetTypesMustDiffer'), {
//                 transform: opts?.aTransform,
//                 otherTransform: opts?.bTransform,
//             }),
//             createDistinctObjectValidator(aFieldKey, t('assetTypesMustDiffer'), {
//                 transform: opts?.bTransform,
//                 otherTransform: opts?.aTransform,
//             }),
//         ],
//         [aFieldKey, bFieldKey, opts?.aTransform, opts?.bTransform, t]
//     )
// }

// const useDistinctLedgerAssetTypeValidatorTranslation = createUseTranslation({
//     FR: {
//         assetTypesMustDiffer: 'Les actifs doivent être différents',
//     },
//     EN: {
//         assetTypesMustDiffer: 'Asset types must be different',
//     },
// })

export const createDistinctObjectValidator =
    (
        key: string,
        errorMessage: string,
        opts?: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            transform?: (obj: any) => { id: string } | undefined
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            otherTransform?: (obj: any) => { id: string } | undefined
        }
    ): Rule =>
    ({ getFieldValue }) => ({
        validator: async (_, thisObj: { id: string } | undefined) => {
            const value = getFieldValue(key) as unknown

            const otherFinalObj = opts?.otherTransform?.(value) ?? (value as { id: string } | undefined)

            const thisFinalObj = opts?.transform?.(thisObj) ?? thisObj

            return new Promise<void>((resolve, reject) => {
                if (thisFinalObj?.id === otherFinalObj?.id) {
                    reject(new Error(errorMessage))
                } else {
                    resolve()
                }
            })
        },
    })

type QuantityRenderer = (quantity: number) => string

export const useQuantityRenderer = (precision?: number): QuantityRenderer => {
    const locale = useCurrentLocale()

    return useCallback(
        (quantity: number) =>
            renderValue({
                type: 'FloatValue',
                floatValue: quantity,
                options: {
                    locale,
                    numberFormat: 'NUMBER',
                    precision,
                },
                context: 'web',
            }),
        [locale, precision]
    )
}

export const useRenderedQuantity = (quantity: number) => {
    const renderer = useQuantityRenderer()

    return useMemo(() => renderer(quantity), [quantity, renderer])
}

export const useQuantityAsPercentageRenderer = (): QuantityRenderer => {
    const renderer = useQuantityRenderer(2)

    return useCallback(
        (quantity: number) => {
            // Convert to percentage
            const asPct = quantity * 100
            const rounded = Math.round(asPct * 100) / 100
            const approx = asPct - rounded !== 0

            const rendered = renderer(rounded)
            return `${approx ? '~' : ''}${rendered}%`
        },
        [renderer]
    )
}

export const useRenderedQuantityAsPercentage = (quantity: number) => {
    const renderer = useQuantityAsPercentageRenderer()
    return useMemo(() => renderer(quantity), [quantity, renderer])
}

type LedgerContextForTranslation = {
    currency: Currency
    currencySymbol: string
}

export const createUseTranslationWithLedgerContext = <T extends TranslationsFor<string>>(
    translations: (context: LedgerContextForTranslation) => T
) => {
    return createUseTranslation((): LedgerContextForTranslation => {
        const { currency } = useLedgerContext()
        return useMemo(
            () => ({
                currency,
                currencySymbol: currencySymbols[currency],
            }),
            [currency]
        )
    }, translations)
}
