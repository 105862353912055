import { Divider } from 'antd'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ControlStrip, VerticalSpacer } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetLedgerAssetTypesQuery } from '../../../../data'
import { CreateLedgerAssetTypeControl } from './CreateLedgerAssetTypeControl'
import { LedgerAssetTypeTable } from './LedgerAssetTypeTable'

type LedgerAssetTypeProps = {
    ledger: { id: string }
}

export const LedgerAssetTypeIndex: FC<LedgerAssetTypeProps> = ({ ledger }) => {
    const ledgerId = ledger.id

    const { loading, data } = useGetLedgerAssetTypesQuery({
        pollInterval: usePollingRate(),
        variables: {
            ledgerId,
        },
    })

    const { t } = useLedgerAssetTypeIndexTranslations()

    const ledgerAssetTypes = useMemo(() => data?.ledgerAssetTypesForLedger ?? [], [data?.ledgerAssetTypesForLedger])

    return (
        <VerticalSpacer size={10}>
            <Divider>{t('assetTypes')}</Divider>
            <>
                <LedgerAssetTypeControls ledgerId={ledgerId} />
                <LedgerAssetTypeTable loading={loading} ledgerAssetTypes={ledgerAssetTypes} />
            </>
        </VerticalSpacer>
    )
}

type LedgerAssetTypeControlsProps = {
    ledgerId: string
}

const LedgerAssetTypeControls: FC<LedgerAssetTypeControlsProps> = ({ ledgerId }) => (
    <ControlStrip>
        <CreateLedgerAssetTypeControl ledgerId={ledgerId} />
    </ControlStrip>
)

const useLedgerAssetTypeIndexTranslations = createUseTranslation({
    EN: {
        assetTypes: 'Asset Types',
    },
    FR: {
        assetTypes: 'Actifs',
    },
})
