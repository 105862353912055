import { Card, Tabs } from 'antd'
import { useEffect, useMemo } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { Layout, Tab, useTabs } from '@publica/ui-web-components'

import { Account, Ledgers } from './pages'

const account = <Account />

const useAppTranslation = createUseTranslation({
    FR: {
        ledgers: 'Registres',
    },
    EN: {
        ledgers: 'Ledgers',
    },
})

export const App: FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useAppTranslation()

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/ledgers')
        }
    }, [location, navigate])

    const tabs = useTabs(
        useMemo(
            (): Tab[] => [
                {
                    key: 'ledgers',
                    label: t('ledgers'),
                    content: <Ledgers />,
                    matches: location => location.pathname.startsWith('/ledgers'),
                },
            ],
            [t]
        )
    )

    const root = useMemo(
        () => (
            <Card>
                <Tabs
                    defaultActiveKey="ledgers"
                    activeKey={tabs.activeTab}
                    onTabClick={tabs.onChange}
                    items={tabs.items}
                />
            </Card>
        ),
        [tabs.activeTab, tabs.items, tabs.onChange]
    )

    return (
        <Layout>
            <Routes>
                <Route path="/account" element={account} />
                <Route path="*" element={root} />
            </Routes>
        </Layout>
    )
}

export default App
