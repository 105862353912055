import { Card, Divider } from 'antd'
import { FilterValue } from 'antd/lib/table/interface'
import isNil from 'lodash/isNil'
import { DateTime } from 'luxon'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { LocalizedString } from '@publica/locales'
import { ledgerAccountTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { DateRange, FilterColumnType, FilterTable, VerticalSpacer } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'
import { assert } from '@publica/utils'

import {
    LedgerAccountType,
    LedgerEntryRole,
    LedgerTransactionType,
    useGetLedgerAssetTypesQuery,
    useGetLedgerBeneficiarySuspenseQuery,
} from '../../../../data'
import {
    LedgerInvestmentTable,
    LedgerQuantity,
    LedgerTransactionTable,
    LedgerTransfersTable,
    useTransactionsToTransferBalance,
} from '../../../components'
import { LedgerBalance } from '../types'

type ShowLedgerBeneficiaryProps = {
    ledger: {
        id: string
    }
}

export const ShowLedgerBeneficiary: FC<ShowLedgerBeneficiaryProps> = ({ ledger }) => {
    const { ledgerBeneficiaryId } = useParams()
    const { t } = useShowLedgerBeneficiaryTranslation()
    const [transactionTypeFilter, setTransactionTypeFilter] = useState<LedgerTransactionType[] | undefined>()
    const [transactionDateRangeFilter, setTransactionDateRangeFilter] = useState<DateRange | undefined>()

    assert.defined(ledgerBeneficiaryId)

    const { data } = useGetLedgerBeneficiarySuspenseQuery({
        variables: {
            ledgerBeneficiaryId,
        },
    })

    const beneficiary = data.ledgerBeneficiary

    const transfers = useTransactionsToTransferBalanceForBeneficiary(beneficiary)

    const onChange = useCallback((_: unknown, filters: Record<string, FilterValue | null>) => {
        const type = filters['type'] as LedgerTransactionType[] | undefined | null
        const date = filters['date'] as [DateRange] | undefined | null

        setTransactionTypeFilter(type ?? undefined)
        setTransactionDateRangeFilter(isNil(date) ? undefined : date[0])
    }, [])

    const transactions = useMemo(
        () =>
            beneficiary.transactions.filter(txn => {
                if (transactionTypeFilter !== undefined && !transactionTypeFilter.includes(txn.type)) {
                    return false
                }

                if (transactionDateRangeFilter !== undefined) {
                    const { before, after } = transactionDateRangeFilter

                    if (before !== undefined && txn.occurredAt > before) {
                        return false
                    }

                    if (after !== undefined && txn.occurredAt < after) {
                        return false
                    }
                }

                return true
            }),
        [beneficiary.transactions, transactionDateRangeFilter, transactionTypeFilter]
    )

    return (
        <Card title={beneficiary.legalEntity.name}>
            <VerticalSpacer size={10}>
                <Divider>{t('holdership')}</Divider>
                <LedgerBeneficiaryBalanceTable beneficiary={beneficiary} ledger={ledger} />
                <Divider>{t('capital')}</Divider>
                <LedgerInvestmentTable balance={beneficiary.balance} />
                <Divider>{t('transfers')}</Divider>
                <LedgerTransfersTable transfers={transfers} />
                <Divider>{t('transactions')}</Divider>
                <LedgerTransactionTable transactions={transactions} onChange={onChange} />
            </VerticalSpacer>
        </Card>
    )
}

const useShowLedgerBeneficiaryTranslation = createUseTranslation({
    FR: {
        transactions: 'Transactions',
        holdership: `Détention`,
        capital: 'Capital et investissements',
        transfers: 'Titres débités',
    },
    EN: {
        transactions: 'Transactions',
        holdership: `Holdership`,
        capital: 'Capital and investments',
        transfers: 'Outgoing transfers',
    },
})

type LedgerTransaction = {
    id: string
    type: LedgerTransactionType
    entries: LedgerEntry[]
    occurredAt: DateTime
}

type LedgerEntry = {
    quantity: number
    unitValue: number
    role: LedgerEntryRole
    assetType: {
        id: string
        name: LocalizedString
    }
    account: {
        id: string
    }
}

const useTransactionsToTransferBalanceForBeneficiary = (beneficiary: LedgerBeneficiary) => {
    const filteredTransactions = useMemo(() => {
        const accountIds = new Set<string>(beneficiary.accounts.map(account => account.id))

        return beneficiary.transactions.filter(txn => {
            if (!(txn.type === 'TRANSFER_CASH' || txn.type === 'TRANSFER_EQUITY')) {
                return false
            }

            const debit = txn.entries.find(
                entry => entry.role === 'BARE_OWNERSHIP_DEBIT' || entry.role === 'FULL_OWNERSHIP_DEBIT'
            )

            if (debit === undefined) {
                return false
            }

            return accountIds.has(debit.account.id)
        })
    }, [beneficiary.accounts, beneficiary.transactions])

    return useTransactionsToTransferBalance(filteredTransactions)
}

type LedgerBeneficiary = {
    id: string
    balance: LedgerBalance
    accounts: {
        id: string
        type: LedgerAccountType
        balance: LedgerBalance
    }[]
    transactions: LedgerTransaction[]
}

type LedgerBeneficiaryBalanceTableProps = {
    ledger: {
        id: string
    }
    beneficiary: LedgerBeneficiary
}

const LedgerBeneficiaryBalanceTable: FC<LedgerBeneficiaryBalanceTableProps> = ({ beneficiary, ledger }) => {
    const { t } = useLedgerBeneficiaryBalanceTableTranslation()
    const resolveLocalizedString = useLocalizedStringResolver()
    const locale = useCurrentLocale()

    const { data, loading } = useGetLedgerAssetTypesQuery({
        variables: {
            ledgerId: ledger.id,
        },
        pollInterval: usePollingRate(),
    })

    const ledgerAssetTypes = useMemo(() => data?.ledgerAssetTypesForLedger ?? [], [data?.ledgerAssetTypesForLedger])

    const columns = useMemo<FilterColumnType<LedgerAssetType>[]>(
        () => [
            {
                title: t('name'),
                render: (_, assetType) => resolveLocalizedString(assetType.name),
            },
            {
                title: t('holdership'),
                align: 'end',
                render: (_, assetType) => {
                    const balance =
                        beneficiary.balance.balanceByAssetType.find(balance => balance.assetType.id === assetType.id)
                            ?.quantity ?? 0
                    return <LedgerQuantity quantity={balance} />
                },
            },
            ...ledgerAccountTypeLookup.keys().map(
                (type): FilterColumnType<LedgerAssetType> => ({
                    title: ledgerAccountTypeLookup.labelForKeyAndLocale(type, locale),
                    align: 'end',
                    render: (_, assetType) => {
                        const balance =
                            beneficiary.accounts
                                .find(account => account.type === type)
                                ?.balance.balanceByAssetType.find(balance => balance.assetType.id === assetType.id)
                                ?.quantity ?? 0

                        return <LedgerQuantity quantity={balance} />
                    },
                })
            ),
        ],
        [beneficiary.accounts, beneficiary.balance.balanceByAssetType, locale, resolveLocalizedString, t]
    )

    return <FilterTable<LedgerAssetType> columns={columns} dataSource={ledgerAssetTypes} loading={loading} />
}

type LedgerAssetType = {
    id: string
    name: LocalizedString
}

const useLedgerBeneficiaryBalanceTableTranslation = createUseTranslation({
    FR: {
        holdership: 'Détention',
        name: 'Actif',
    },
    EN: {
        holdership: 'Holdership',
        name: 'Asset type',
    },
})
