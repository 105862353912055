import { Divider } from 'antd'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { VerticalSpacer } from '@publica/ui-web-components'
import { assert } from '@publica/utils'

import { useGetLedgerBalanceSuspenseQuery } from '../../../../data'
import { useLedgerContext } from '../../../context'
import { LedgerGroupsTable } from './LedgerGroupsTable'

type LedgerAccountsIndexProps = {
    ledger: {
        id: string
    }
}

export const LedgerAccountsIndex: FC<LedgerAccountsIndexProps> = props => {
    const { cursor } = useLedgerContext()

    const { data } = useGetLedgerBalanceSuspenseQuery({
        variables: {
            ledgerId: props.ledger.id,
            cursor,
        },
    })

    const { t } = useLedgerAccountsIndexTranslations()

    const ledger = data.ledger
    assert.defined(ledger)

    return (
        <VerticalSpacer size={10}>
            <Divider>{t('accounts')}</Divider>
            <LedgerGroupsTable ledger={ledger} />
        </VerticalSpacer>
    )
}

const useLedgerAccountsIndexTranslations = createUseTranslation({
    FR: {
        accounts: `Comptes d'actionnaires`,
    },
    EN: {
        accounts: `Shareholder accounts`,
    },
})
