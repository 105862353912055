import { FC } from '@publica/ui-common-utils'
import { ControlStrip } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetLedgersQuery } from '../../../../data'
import { LedgerTable } from './LedgerTable'
import { NewLedgerControl } from './NewLedgerControl'

export const LedgerIndex: FC = () => {
    const { loading, data } = useGetLedgersQuery({ pollInterval: usePollingRate() })

    const ledgers = data?.ledgers ?? []

    return (
        <>
            <LedgerControls />
            <LedgerTable loading={loading} ledgers={ledgers} />
        </>
    )
}

const LedgerControls: FC = () => (
    <ControlStrip>
        <NewLedgerControl />
    </ControlStrip>
)
