import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ModalForm, icons } from '@publica/ui-web-components'

import { GetLedgerBalanceDocument, GetLedgerTransactionsDocument } from '../../../../../data'
import { useNewLedgerTransactionForm } from '../../NewLedgerTransactionForm'

const useNewLedgerTransactionControlTranslation = createUseTranslation({
    FR: {
        createLedgerTransaction: 'Créer une transaction',
    },
    EN: {
        createLedgerTransaction: 'Create a transaction',
    },
})

type NewLedgerTransactionControlProps = {
    ledger: {
        id: string
    }
}

export const NewLedgerTransactionControl: FC<NewLedgerTransactionControlProps> = ({ ledger }) => {
    const form = useNewLedgerTransactionForm(ledger.id, {
        refetchQueries: [GetLedgerTransactionsDocument, GetLedgerBalanceDocument],
    })

    const { t } = useNewLedgerTransactionControlTranslation()

    return <ModalForm form={form} icon={icons.Add} title={t('createLedgerTransaction')} />
}
