import { ColumnGroupType } from 'antd/lib/table'
import { useMemo } from 'react'

import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FilterColumnType } from '@publica/ui-web-components'

import { useGetLedgerAssetTypesQuery } from '../../../../data'
import { LedgerQuantity, NotApplicable } from '../../../components'
import { LedgerBalance } from '../types'

type Ledger = {
    id: string
    balance?: LedgerBalance
}

type ShareTableRow = {
    balance: LedgerBalance
}

export const useShareTableColumns = <R extends ShareTableRow>(ledger: Ledger) => {
    const { t } = useShareTableTranslations()
    const resolveLocalizedString = useLocalizedStringResolver()

    const { loading, data } = useGetLedgerAssetTypesQuery({
        variables: {
            ledgerId: ledger.id,
        },
    })

    const assetTypes = useMemo(() => data?.ledgerAssetTypesForLedger ?? [], [data?.ledgerAssetTypesForLedger])

    const columns = useMemo<(FilterColumnType<R> | ColumnGroupType<R>)[]>(
        () => [
            {
                title: t('shareCapital'),
                children: [
                    {
                        title: t('holdershipAsQuantity'),
                        align: 'end',
                        render: (_, row) => <LedgerQuantity quantity={row.balance.shareCapital} />,
                    },
                    {
                        title: t('holdershipAsPercentage'),
                        align: 'end',
                        render: (_, row) => {
                            const totalShareCapital = ledger.balance?.shareCapital
                            if (totalShareCapital === undefined || totalShareCapital === 0) {
                                return <NotApplicable />
                            }

                            return (
                                <LedgerQuantity
                                    quantity={row.balance.shareCapital / totalShareCapital}
                                    format="percentage"
                                />
                            )
                        },
                    },
                ],
            },
            ...assetTypes.map((assetType): ColumnGroupType<R> => {
                const findBalance = (row: R) =>
                    row.balance.balanceByAssetType.find(balance => balance.assetType.id === assetType.id)?.quantity ?? 0

                return {
                    title: resolveLocalizedString(assetType.name),
                    children: [
                        {
                            title: t('holdershipAsQuantity'),
                            align: 'end',
                            render: (_, row) => {
                                const assetTypeBalance = findBalance(row)
                                return <LedgerQuantity quantity={assetTypeBalance} />
                            },
                        },
                        {
                            title: t('holdershipAsPercentage'),
                            align: 'end',
                            render: (_, row) => {
                                const totalQuantity = ledger.balance?.balanceByAssetType.find(
                                    balance => balance.assetType.id === assetType.id
                                )?.quantity

                                if (totalQuantity === undefined || totalQuantity === 0) {
                                    return <NotApplicable />
                                }

                                const assetTypeBalance = findBalance(row)

                                return (
                                    <LedgerQuantity quantity={assetTypeBalance / totalQuantity} format="percentage" />
                                )
                            },
                        },
                    ],
                    align: 'center',
                }
            }),
        ],
        [assetTypes, ledger.balance?.balanceByAssetType, ledger.balance?.shareCapital, resolveLocalizedString, t]
    )

    return { loading, columns }
}

const useShareTableTranslations = createUseTranslation({
    FR: {
        shareCapital: 'Capital social',
        holdershipAsQuantity: 'Détention',
        holdershipAsPercentage: 'Détention (%)',
    },
    EN: {
        shareCapital: 'Share capital',
        holdershipAsQuantity: 'Holdership',
        holdershipAsPercentage: 'Holdership (%)',
    },
})
