import { useCallback, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { LedgerMovement, LedgerMovementHistory, LedgerMovementLabelKey } from '../LedgerMovementHistory'
import { LedgerTransfer } from '../LedgerTransfersTable'

type LedgerTransferHistoryProps = {
    transfers: LedgerTransfer[]
}

export const LedgerTransferHistory: FC<LedgerTransferHistoryProps> = ({ transfers }) => {
    const { t } = useLedgerTransferHistoryTranslation()

    const label = useCallback((key: LedgerMovementLabelKey) => t(key), [t])
    const movements = useMemo(
        (): LedgerMovement[] =>
            transfers.map(({ totalTransferred, totalTransferredCash, totalTransferredEquity, ...other }) => ({
                total: totalTransferred,
                totalCash: totalTransferredCash,
                totalEquity: totalTransferredEquity,
                ...other,
            })),
        [transfers]
    )

    return <LedgerMovementHistory movements={movements} label={label} />
}

const useLedgerTransferHistoryTranslation = createUseTranslation({
    FR: {
        total: `Montant total transféré`,
        totalCash: `Montant total transféré (cash)`,
        totalEquity: `Montant total transféré (nature)`,
    },
    EN: {
        total: `Total transferred amount`,
        totalCash: `Total transferred amount (cash)`,
        totalEquity: `Total transferred amount (nature)`,
    },
})
