import { Alert, Card } from 'antd'

import { FC } from '@publica/ui-common-utils'
import { VerticalSpacer } from '@publica/ui-web-components'

import { LedgerEntryTable } from '../LedgerEntryTable/LedgerEntryTable'
import { LedgerTransaction } from '../types'

type LedgerTransactionInfoProps = {
    transaction: LedgerTransaction
}

export const LedgerTransactionInfo: FC<LedgerTransactionInfoProps> = ({ transaction }) => (
    <Card>
        <VerticalSpacer size={15}>
            {(transaction.comment?.length ?? 0) > 0 ? (
                <Alert showIcon type="info" message={transaction.comment} />
            ) : null}
            <LedgerEntryTable transaction={transaction} />
        </VerticalSpacer>
    </Card>
)
