// import { Col, Row } from 'antd'
import { Col, Divider, Row } from 'antd'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { VerticalSpacer } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

import { LedgerTransactionTable } from './LedgerTransactionTable'
import { NewLedgerTransactionControl } from './NewLedgerTransactionControl'

// import { utils } from '@publica/ui-web-styles'

// import { NewLedgerTransactionControl } from './NewLedgerTransactionControl'

type LedgerTransactionIndexProps = {
    ledger: { id: string }
}

export const LedgerTransactionIndex: FC<LedgerTransactionIndexProps> = ({ ledger }) => {
    const { t } = useLedgerTransactionIndexTranslations()

    return (
        <VerticalSpacer size={10}>
            <Divider>{t('transactions')}</Divider>
            <LedgerTransactionControls ledger={ledger} />
            <LedgerTransactionTable ledger={ledger} />
        </VerticalSpacer>
    )
}

const useLedgerTransactionIndexTranslations = createUseTranslation({
    EN: {
        transactions: 'Transactions',
    },
    FR: {
        transactions: 'Transactions',
    },
})

type LedgerTransactionControlsProps = {
    ledger: {
        id: string
    }
}

const LedgerTransactionControls: FC<LedgerTransactionControlsProps> = ({ ledger }) => {
    const styles = utils.useControlsStyles()

    return (
        <Row justify="end" className={styles.controls}>
            <Col>
                <NewLedgerTransactionControl ledger={ledger} />
            </Col>
        </Row>
    )
}
