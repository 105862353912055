import { InMemoryCache } from '@apollo/client'

import '@publica/ui-common-polyfill'
import { boot } from '@publica/ui-web-boot'

import App from './app/app'
import { Login, authStateBuilder } from './auth'
import { possibleTypes } from './data/fragments.generated.json'
import './styles.less'

boot(<App />, {
    auth: {
        stateBuilder: authStateBuilder,
        login: <Login />,
    },
    apollo: {
        cache: new InMemoryCache({
            typePolicies: {
                Ledger: {
                    fields: {
                        transactions: {
                            keyArgs: false,
                        },
                    },
                },
                LedgerGroup: {
                    fields: {
                        transactions: {
                            keyArgs: false,
                        },
                    },
                },
            },
            possibleTypes,
        }),
    },
})
