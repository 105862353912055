import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ModalForm, icons } from '@publica/ui-web-components'

import { GetLedgerAssetTypesDocument } from '../../../../../data'
import { useNewLedgerAssetTypeForm } from '../NewLedgerAssetTypeForm'

type CreateLedgerAssetTypeControlProps = {
    ledgerId: string
}

export const CreateLedgerAssetTypeControl: FC<CreateLedgerAssetTypeControlProps> = ({ ledgerId }) => {
    const form = useNewLedgerAssetTypeForm(ledgerId, {
        refetchQueries: [GetLedgerAssetTypesDocument],
    })

    const { t } = useLedgerAssetTypeControlsTranslation()

    return <ModalForm title={t('createLedgerAssetType')} form={form} icon={icons.Add} />
}

const useLedgerAssetTypeControlsTranslation = createUseTranslation({
    FR: {
        createLedgerAssetType: 'Créer actif',
    },
    EN: {
        createLedgerAssetType: 'Create asset type',
    },
})
