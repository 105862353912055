import { FC } from '@publica/ui-common-utils'

type LedgerNameProps = {
    ledger: {
        name: string
        company: {
            identifier: string
        }
    }
}

export const LedgerName: FC<LedgerNameProps> = ({ ledger }) => `${ledger.name} (${ledger.company.identifier})`
