import { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { LedgerTransactionIndex } from './LedgerTransactionIndex'

type LedgerTransactionsProps = {
    ledger: { id: string }
}

export const LedgerTransactions: FC<LedgerTransactionsProps> = ({ ledger }) => {
    const ledgerTransactionIndex = useMemo(() => <LedgerTransactionIndex ledger={ledger} />, [ledger])

    return (
        <Routes>
            <Route path="transactions/*" element={ledgerTransactionIndex}></Route>
        </Routes>
    )
}
