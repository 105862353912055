import isNil from 'lodash/isNil'

import { KnownLocale } from '@publica/locales'
import { LedgerAccountType, ledgerAccountTypeLookup } from '@publica/lookups'
import { useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { NotApplicable } from '../NotApplicable'

type LedgerAccount = {
    type: LedgerAccountType
    beneficiary: {
        legalEntity: {
            name: string
        }
    }
}

type LedgerAccountLabelProps = {
    account: LedgerAccount | undefined | null
}

export const LedgerAccountLabel: FC<LedgerAccountLabelProps> = ({ account }) => {
    const locale = useCurrentLocale()
    const label = ledgerAccountLabel(account, locale)

    if (label === null) {
        return <NotApplicable />
    }

    return <>{label}</>
}

export function ledgerAccountLabel(account: LedgerAccount, locale: KnownLocale): string
export function ledgerAccountLabel(account: LedgerAccount | null | undefined, locale: KnownLocale): string | null
export function ledgerAccountLabel(account: LedgerAccount | undefined | null, locale: KnownLocale) {
    if (isNil(account)) {
        return null
    }

    if (account.type !== 'STANDARD') {
        return `${account.beneficiary.legalEntity.name} (${ledgerAccountTypeLookup.labelForKeyAndLocale(
            account.type,
            locale
        )})`
    }

    return account.beneficiary.legalEntity.name
}
