import { ExpandableConfig } from 'antd/lib/table/interface'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { LocalizedString } from '@publica/locales'
import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'

import { LedgerEntryRole, LedgerTransactionType } from '../../../data'
import { LedgerCurrencyAmount } from '../LedgerCurrencyAmount'
import { LedgerTransferHistory } from '../LedgerTransferHistory'

type LedgerTransfersTableProps = {
    transfers: LedgerTransferBalance & { balanceByAssetType: LedgerAssetTypeTransferBalance[] }
}

export const LedgerTransfersTable: FC<LedgerTransfersTableProps> = ({ transfers }) => {
    const resolveLocalizedString = useLocalizedStringResolver()
    const { t } = useLedgerTransfersTableTranslation()

    const rows = useMemo(
        (): LedgerTransferTableRow[] => [
            {
                ...transfers,
                id: 'total',
                name: t('total'),
            },
            ...transfers.balanceByAssetType
                .map(balanceByAssetType => ({
                    id: balanceByAssetType.assetType.id,
                    name: resolveLocalizedString(balanceByAssetType.assetType.name),
                    totalTransferred: balanceByAssetType.totalTransferred,
                    totalTransferredCash: balanceByAssetType.totalTransferredCash,
                    totalTransferredEquity: balanceByAssetType.totalTransferredEquity,
                    transfersByDate: balanceByAssetType.transfersByDate,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
        ],
        [transfers, resolveLocalizedString, t]
    )

    const columns = useMemo(
        (): FilterColumnType<LedgerTransferTableRow>[] => [
            {
                key: 'name',
                render: (_, row) => row.name,
            },
            ...(['totalTransferred', 'totalTransferredCash', 'totalTransferredEquity'] as const).map(
                (key): FilterColumnType<LedgerTransferTableRow> => ({
                    title: t(key),
                    align: 'end',
                    key,
                    render: (_, row) => <LedgerCurrencyAmount quantity={row[key]} />,
                })
            ),
        ],
        [t]
    )

    const expandable = useMemo(
        (): ExpandableConfig<LedgerTransferTableRow> => ({
            expandedRowRender: row => <LedgerTransferHistory transfers={row.transfersByDate} />,
            rowExpandable: row => row.transfersByDate.length > 0,
        }),
        []
    )

    return <FilterTable<LedgerTransferTableRow> columns={columns} dataSource={rows} expandable={expandable} />
}

const useLedgerTransfersTableTranslation = createUseTranslation({
    FR: {
        total: 'Total',
        totalTransferred: 'Montant total transféré',
        totalTransferredCash: 'Montant total transféré (cash)',
        totalTransferredEquity: 'Montant total transféré (nature)',
    },
    EN: {
        total: 'Total',
        totalTransferred: 'Total transferred amount',
        totalTransferredCash: 'Total transferred amount (cash)',
        totalTransferredEquity: 'Total transferred amount (equity)',
    },
})

type LedgerTransferTableRow = {
    id: string
    name: string
    transfersByDate: LedgerTransfer[]
} & LedgerTransferIndicators

type LedgerTransferIndicators = {
    totalTransferred: number
    totalTransferredCash: number
    totalTransferredEquity: number
}

export type LedgerTransfer = LedgerTransferIndicators & { id: string; occurredAt: DateTime }

type LedgerTransferBalance = {
    transfersByDate: LedgerTransfer[]
} & LedgerTransferIndicators

type LedgerAssetTypeTransferBalance = LedgerTransferBalance & {
    assetType: {
        id: string
        name: LocalizedString
    }
}

type LedgerTransaction = {
    id: string
    occurredAt: DateTime
    type: LedgerTransactionType
    entries: LedgerEntry[]
}

type LedgerEntry = {
    quantity: number
    role: LedgerEntryRole
    unitValue: number
    assetType: {
        id: string
        name: LocalizedString
    }
}

export const useTransactionsToTransferBalance = (
    transactions: LedgerTransaction[]
): LedgerTransfersTableProps['transfers'] => useMemo(() => transactionsToTransferBalance(transactions), [transactions])

export const transactionsToTransferBalance = (
    transactions: LedgerTransaction[]
): LedgerTransfersTableProps['transfers'] => {
    const transferTransactions = transactions.filter(
        txn => txn.type === 'TRANSFER_CASH' || txn.type === 'TRANSFER_EQUITY'
    )

    const transferBalance: LedgerTransfersTableProps['transfers'] = {
        totalTransferred: 0,
        totalTransferredCash: 0,
        totalTransferredEquity: 0,
        transfersByDate: [],
        balanceByAssetType: [],
    }

    for (const transaction of transferTransactions) {
        const debit = transaction.entries.find(
            entry => entry.role === 'BARE_OWNERSHIP_DEBIT' || entry.role === 'FULL_OWNERSHIP_DEBIT'
        )

        if (debit === undefined) {
            continue
        }

        const amount = Math.abs(debit.quantity) * debit.unitValue

        let transferForDate = transferBalance.transfersByDate.find(
            transfer => transfer.occurredAt === transaction.occurredAt
        )

        if (transferForDate === undefined) {
            transferForDate = {
                id: transaction.occurredAt.toMillis().toString(),
                occurredAt: transaction.occurredAt,
                totalTransferred: 0,
                totalTransferredCash: 0,
                totalTransferredEquity: 0,
            }
            transferBalance.transfersByDate.push(transferForDate)
        }

        let balanceForAssetType = transferBalance.balanceByAssetType.find(
            balance => balance.assetType.id === debit.assetType.id
        )

        if (balanceForAssetType === undefined) {
            balanceForAssetType = {
                totalTransferred: 0,
                totalTransferredCash: 0,
                totalTransferredEquity: 0,
                transfersByDate: [],
                assetType: debit.assetType,
            }
            transferBalance.balanceByAssetType.push(balanceForAssetType)
        }

        let transferForDateForAssetType = balanceForAssetType.transfersByDate.find(
            transfer => transfer.occurredAt === transaction.occurredAt
        )

        if (transferForDateForAssetType === undefined) {
            transferForDateForAssetType = {
                id: transaction.occurredAt.toMillis().toString(),
                occurredAt: transaction.occurredAt,
                totalTransferred: 0,
                totalTransferredCash: 0,
                totalTransferredEquity: 0,
            }
            balanceForAssetType.transfersByDate.push(transferForDateForAssetType)
        }

        switch (transaction.type) {
            case 'TRANSFER_CASH':
                transferBalance.totalTransferredCash += amount
                transferForDate.totalTransferredCash += amount
                balanceForAssetType.totalTransferredCash += amount
                transferForDateForAssetType.totalTransferredCash += amount
                break
            case 'TRANSFER_EQUITY':
                transferBalance.totalTransferredEquity += amount
                transferForDate.totalTransferredEquity += amount
                balanceForAssetType.totalTransferredEquity += amount
                transferForDateForAssetType.totalTransferredEquity += amount
                break
        }

        transferBalance.totalTransferred += amount
        transferForDate.totalTransferred += amount
        balanceForAssetType.totalTransferred += amount
        transferForDateForAssetType.totalTransferred += amount
    }

    return transferBalance
}
