import { ColumnType } from 'antd/lib/table'
import { ExpandableConfig } from 'antd/lib/table/interface'
import orderBy from 'lodash/orderBy'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { ledgerTransactionTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { DateRangeFilter, FilterColumnType, FilterTable, FilterTableProps } from '@publica/ui-web-components'
import { buildMapWithFn } from '@publica/utils'

import { LedgerEntryRole, LedgerTransactionType } from '../../../data'
import { formatTransactionSequence } from '../../lib'
import { LedgerQuantity } from '../LedgerQuantity'
import { NotApplicable } from '../NotApplicable'
import { LedgerTransactionInfo } from './LedgerTransactionInfo'
import { LedgerAccount, LedgerAssetType, LedgerEntry, LedgerTransaction } from './types'

type LedgerTransactionTableProps = {
    transactions: LedgerTransaction[]
} & Pick<FilterTableProps<TransactionInTableFormat>, 'title' | 'loading' | 'pagination' | 'onChange'>

const useLedgerTransactionTableTranslation = createUseTranslation({
    FR: {
        type: 'Type',
        date: 'Date',
        comment: ' Commentaire',
        historical: `Reprise de l'existant`,
        sequence: '#',
        status: 'État',
        description: 'Description',
        debitedAccounts: 'Comptes débités',
        debitedAssetType: 'Actif débité',
        debitedAmount: 'Nombre de titres débités',
        creditedAccounts: 'Comptes crédités',
        creditedAssetType: 'Actif crédité',
        creditedAmount: 'Nombre de titres crédités',
    },
    EN: {
        type: 'Type',
        date: 'Date',
        comment: ' Comment',
        historical: 'Historical',
        sequence: '#',
        status: 'Status',
        description: 'Description',
        debitedAccounts: 'Debited accounts',
        debitedAssetType: 'Debited asset Type',
        debitedAmount: 'Number of shares debited',
        creditedAccounts: 'Credited accounts',
        creditedAssetType: 'Credited asset Type',
        creditedAmount: 'Number of shared credited',
    },
})

export const LedgerTransactionTable: FC<LedgerTransactionTableProps> = props => {
    const { t } = useLedgerTransactionTableTranslation()
    const locale = useCurrentLocale()
    const resolveLocalizedString = useLocalizedStringResolver()

    const transactions = useMemo(
        () => orderBy(props.transactions, ['status', 'sequence', 'internalSequence'], ['desc', 'desc', 'desc']),
        [props.transactions]
    )

    const transactionsInTableFormat = useTransactionsInTableFormat(transactions)

    const expandable: ExpandableConfig<TransactionInTableFormat> = useMemo(
        () => ({
            expandedRowRender: (transactionInTableFormat: TransactionInTableFormat) => (
                <LedgerTransactionInfo transaction={transactionInTableFormat.transaction} />
            ),
        }),
        []
    )

    const columns = useMemo<(FilterColumnType<TransactionInTableFormat> | ColumnType<TransactionInTableFormat>)[]>(
        () => [
            {
                title: t('sequence'),
                render: (_, transaction) =>
                    formatTransactionSequence(transaction.sequence, { skipHash: true }) ?? <NotApplicable />,
                width: 40,
            },
            {
                title: t('date'),
                key: 'date',
                render: (_, transaction) => transaction.occurredAt.toLocaleString(DateTime.DATE_SHORT),
                width: 100,
                filterDropdown: props => <DateRangeFilter {...props} />,
            },
            {
                title: t('type'),
                key: 'type',
                render: (_, transaction) => ledgerTransactionTypeLookup.labelForKeyAndLocale(transaction.type, locale),
                filters: ledgerTransactionTypeLookup.entriesForLocale(locale).map(items => ({
                    text: items.value,
                    value: items.key,
                })),
                width: 300,
            },
            {
                title: t('debitedAccounts'),
                render: (_, transaction) =>
                    Array.from(
                        new Set(transaction.debitedAccounts.map(account => account.beneficiary.legalEntity.name))
                    ).join(', '),
            },
            {
                title: t('debitedAssetType'),
                render: (_, transaction) => resolveLocalizedString(transaction.debitedAssetType?.name),
            },
            {
                title: t('debitedAmount'),
                align: 'end',
                render: (_, transaction) => <LedgerQuantity quantity={transaction.debitedQuantity} />,
            },
            {
                title: t('creditedAccounts'),
                render: (_, transaction) =>
                    Array.from(
                        new Set(transaction.creditedAccounts.map(account => account.beneficiary.legalEntity.name))
                    ).join(', '),
            },
            {
                title: t('creditedAssetType'),
                render: (_, transaction) => resolveLocalizedString(transaction.creditedAssetType?.name),
            },
            {
                title: t('creditedAmount'),
                align: 'end',
                render: (_, transaction) => <LedgerQuantity quantity={transaction.creditedQuantity} />,
            },
        ],
        [t, locale, resolveLocalizedString]
    )

    return (
        <FilterTable
            title={props.title}
            dataSource={transactionsInTableFormat}
            rowKey="id"
            loading={props.loading}
            columns={columns}
            pagination={props.pagination}
            onChange={props.onChange}
            expandable={expandable}
        />
    )
}

type TransactionInTableFormat = {
    id: string
    sequence: number | undefined
    occurredAt: DateTime
    type: LedgerTransactionType
    transaction: LedgerTransaction
    debitedAccounts: LedgerAccount[]
    debitedAssetType?: LedgerAssetType
    debitedQuantity?: number
    creditedAccounts: LedgerAccount[]
    creditedAssetType?: LedgerAssetType
    creditedQuantity?: number
}

const useTransactionsInTableFormat = (transactions: LedgerTransaction[]) =>
    useMemo(
        (): TransactionInTableFormat[] =>
            transactions.map(transaction => {
                const txn: TransactionInTableFormat = {
                    id: transaction.id,
                    sequence: transaction.sequence ?? undefined,
                    occurredAt: transaction.occurredAt,
                    type: transaction.type,
                    creditedAccounts: [],
                    debitedAccounts: [],
                    transaction,
                }

                const updateDebitInfo = (accounts: LedgerAccount[], quantity: number, assetType: LedgerAssetType) => {
                    txn.debitedAccounts.push(...accounts)
                    // We want to display the absolute amount
                    txn.debitedQuantity = quantity * -1
                    txn.debitedAssetType = assetType
                }

                const updateCreditInfo = (accounts: LedgerAccount[], quantity: number, assetType: LedgerAssetType) => {
                    txn.creditedAccounts.push(...accounts)
                    txn.creditedQuantity = quantity
                    txn.creditedAssetType = assetType
                }

                const entriesByRole: Partial<Record<LedgerEntryRole, LedgerEntry>> = buildMapWithFn(
                    entry => entry.role,
                    transaction.entries
                )

                const getEntryForRole = (role: LedgerEntryRole) => {
                    const entry = entriesByRole[role]
                    if (entry === undefined) {
                        throw new Error(`Unable to find ${role} entry for transaction ${transaction.id}`)
                    }
                    return entry
                }

                switch (transaction.type) {
                    case 'CANCELLATION':
                        {
                            const entry = getEntryForRole('FULL_OWNERSHIP_DEBIT')
                            updateDebitInfo([entry.account], entry.quantity, entry.assetType)
                        }
                        break
                    case 'CONVERSION':
                    case 'DONATION_FULL_OWNERSHIP':
                    case 'PLEDGE':
                    case 'PLEDGE_RELEASE':
                    case 'TRANSFER_CASH':
                    case 'TRANSFER_EQUITY':
                        {
                            const debit = getEntryForRole('FULL_OWNERSHIP_DEBIT')
                            updateDebitInfo([debit.account], debit.quantity, debit.assetType)
                            const credit = getEntryForRole('FULL_OWNERSHIP_CREDIT')
                            updateCreditInfo([credit.account], credit.quantity, credit.assetType)
                        }
                        break
                    case 'EMISSION':
                    case 'SUBSCRIPTION_CASH':
                    case 'SUBSCRIPTION_EQUITY':
                        {
                            const entry = getEntryForRole('FULL_OWNERSHIP_CREDIT')
                            updateCreditInfo([entry.account], entry.quantity, entry.assetType)
                        }
                        break
                    case 'RECONSTITUTION':
                        {
                            const debitA = getEntryForRole('BARE_OWNERSHIP_DEBIT')
                            const debitB = getEntryForRole('USUFRUCT_DEBIT')
                            updateDebitInfo([debitA.account, debitB.account], debitA.quantity, debitA.assetType)
                            const credit = getEntryForRole('FULL_OWNERSHIP_CREDIT')
                            updateCreditInfo([credit.account], credit.quantity, credit.assetType)
                        }
                        break
                    case 'DONATION_DISMEMBERMENT':
                        {
                            const debit = getEntryForRole('FULL_OWNERSHIP_DEBIT')
                            updateDebitInfo([debit.account], debit.quantity, debit.assetType)
                            const creditA = getEntryForRole('BARE_OWNERSHIP_CREDIT')
                            const creditB = getEntryForRole('USUFRUCT_CREDIT')
                            updateCreditInfo([creditA.account, creditB.account], creditA.quantity, creditA.assetType)
                        }
                        break
                }

                return txn
            }),
        [transactions]
    )
