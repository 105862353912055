import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'
import { sort } from '@publica/utils'

import { humanDate } from '../../../../../../packages/ui/common/labels/src/lib/date'
import { LedgerCurrencyAmount } from '../LedgerCurrencyAmount'

const labels = ['total', 'totalCash', 'totalEquity'] as const

export type LedgerMovementLabelKey = (typeof labels)[number]

type LedgerMovementHistoryProps = {
    movements: LedgerMovement[]
    label: (key: LedgerMovementLabelKey) => string
}

export const LedgerMovementHistory: FC<LedgerMovementHistoryProps> = ({ movements, label }) => {
    const { t } = useLedgerMovementHistoryTranslation()

    const rows = useMemo(() => sort.sortByDateTime(movements, 'occurredAt'), [movements])

    const columns = useMemo(
        (): FilterColumnType<LedgerMovement>[] => [
            {
                title: t('occurredAt'),
                render: (_, investment) => humanDate(investment.occurredAt, true),
            },
            ...labels.map(
                (key): FilterColumnType<LedgerMovement> => ({
                    title: label(key),
                    align: 'end',
                    render: (_, investment) => <LedgerCurrencyAmount quantity={investment[key]} />,
                })
            ),
        ],
        [label, t]
    )

    return <FilterTable<LedgerMovement> columns={columns} dataSource={rows} />
}

const useLedgerMovementHistoryTranslation = createUseTranslation({
    FR: {
        occurredAt: 'Date',
    },
    EN: {
        occurredAt: 'Date',
    },
})

export type LedgerMovement = {
    id: string
    occurredAt: DateTime
    total: number
    totalCash: number
    totalEquity: number
}
