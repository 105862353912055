import { DatePicker, Divider, Space, Switch } from 'antd'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { LedgerCursorTransactionStatus } from '../../../../../../data'
import { useLedgerContext } from '../../../../../context'

export const LedgerContextControl: FC = () => {
    const { cursor } = useLedgerContext()

    const updateIncludeUncommitted = useCallback(
        (status: LedgerCursorTransactionStatus) => {
            cursor.setCursorContext(cursor => ({
                ...cursor,
                status,
            }))
        },
        [cursor]
    )

    const updateAsOf = useCallback(
        (asOf: DateTime | undefined) => {
            cursor.setCursorContext(cursor => ({
                ...cursor,
                asOf,
            }))
        },
        [cursor]
    )

    return (
        <Space>
            <LedgerIncludeUncommittedControl status={cursor.status} onChange={updateIncludeUncommitted} />
            <Divider type="vertical" />
            <LedgerViewAsOfControl asOf={cursor.asOf} onChange={updateAsOf} />
        </Space>
    )
}

const useLedgerContextControlStyles = createUseStyles({
    label: {
        paddingRight: 10,
    },
})

const useLedgerIncludeUncommittedControlTranslations = createUseTranslation({
    EN: {
        includeUncommitted: 'Include uncommitted',
    },
    FR: {
        includeUncommitted: 'Inclure les mouvements brouillons',
    },
})

type LedgerIncludeUncommittedControlProps = {
    status: LedgerCursorTransactionStatus
    onChange: (status: LedgerCursorTransactionStatus) => void
}

const LedgerIncludeUncommittedControl: FC<LedgerIncludeUncommittedControlProps> = ({ status, onChange }) => {
    const styles = useLedgerContextControlStyles()
    const { t } = useLedgerIncludeUncommittedControlTranslations()

    const includeUncommitted = status === 'ALL_TRANSACTIONS'

    const toggle = useCallback(
        (value: boolean) => {
            onChange(value ? 'ALL_TRANSACTIONS' : 'ONLY_COMMITTED_TRANSACTIONS')
        },
        [onChange]
    )

    return (
        <>
            <span className={styles.label}>{t('includeUncommitted')}</span>
            <Switch onChange={toggle} value={includeUncommitted} />
        </>
    )
}

const useLedgerViewAsOfControlTranslations = createUseTranslation({
    EN: {
        viewAsOf: 'View ledger as of',
    },
    FR: {
        viewAsOf: 'Voir le registre à la date du',
    },
})

type LedgerViewAsOfControlProps = {
    asOf: DateTime | undefined
    onChange: (asOf?: DateTime) => void
}

const LedgerViewAsOfControl: FC<LedgerViewAsOfControlProps> = ({ asOf, onChange }) => {
    const styles = useLedgerContextControlStyles()
    const { t } = useLedgerViewAsOfControlTranslations()

    const pickDate = useCallback(
        (date: DateTime | undefined) => {
            // The date picker gives us a date time with a tz, but we
            // just want a "date", without a timezone, so we force it
            // to UTC
            onChange(date?.setZone('UTC', { keepLocalTime: true }))
        },
        [onChange]
    )

    return (
        <>
            <span className={styles.label}>{t('viewAsOf')}</span>
            <DatePicker value={asOf} onChange={pickDate} />
        </>
    )
}
