import { Form } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { DateTime } from 'luxon'
import { CSSProperties, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { DatePicker } from '@publica/ui-web-components'
import { FormRules, asyncValidator, useCommonRules } from '@publica/ui-web-utils'

import { useGetMostRecentLedgerTransactionLazyQuery } from '../../../../../../../data'

type TransactionContextFormProps = {
    ledgerId: string
}

export type TransactionContextFormValues = {
    occurredAt?: DateTime | undefined
    comment?: string
}

export const TransactionContextForm: FC<TransactionContextFormProps> = ({ children, ledgerId }) => {
    const [getMostRecentLedgerTransactionLazyQuery] = useGetMostRecentLedgerTransactionLazyQuery()

    const rules = useCommonRules()
    const { t } = useTransactionContextFormTranslation()

    const initialValue = useMemo(() => DateTime.utc(), [])

    const validation: FormRules<TransactionContextFormValues> = useMemo(
        () => ({
            occurredAt: [
                ...rules.required,
                asyncValidator(async (occurredAt: TransactionContextFormValues['occurredAt']) => {
                    if (occurredAt === undefined) {
                        return
                    }

                    const mostRecentTransaction = await getMostRecentLedgerTransactionLazyQuery({
                        variables: {
                            ledgerId,
                        },
                        fetchPolicy: 'no-cache',
                    }).then(response => response.data?.ledger?.transactions.edges[0]?.node)

                    if (mostRecentTransaction === undefined) {
                        return
                    }

                    if (mostRecentTransaction.occurredAt > occurredAt) {
                        throw new Error(t('occurredAtTooOld'))
                    }
                }),
            ],
        }),
        [getMostRecentLedgerTransactionLazyQuery, ledgerId, rules.required, t]
    )

    return (
        <>
            <Form.Item
                name="occurredAt"
                label={t('occurredAt')}
                initialValue={initialValue}
                hasFeedback
                rules={validation.occurredAt}
            >
                <DatePicker style={occurredAtStyle} />
            </Form.Item>
            {children}
            <Form.Item name="comment" label={t('comment')} hasFeedback>
                <TextArea />
            </Form.Item>
        </>
    )
}

const occurredAtStyle: CSSProperties = {
    width: '100%',
}

const useTransactionContextFormTranslation = createUseTranslation({
    FR: {
        occurredAt: 'Date de la transaction',
        comment: 'Commentaire',
        historical: `Reprise de l'existant`,
        occurredAtTooOld: `Vous ne pouvez pas créer une transaction qui est antérieure à la transaction la plus récente`,
    },
    EN: {
        occurredAt: 'Transaction date',
        comment: 'Comment',
        historical: 'Historical transaction',
        occurredAtTooOld: `You cannot create a transaction that occurred before the most recent transaction`,
    },
})
