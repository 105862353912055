import { useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'

import { useLedgerContext } from '../../context'
import { LedgerQuantity } from '../LedgerQuantity'

type LedgerCurrencyAmountProps = {
    quantity: number
}

export const LedgerCurrencyAmount: FC<LedgerCurrencyAmountProps> = ({ quantity }) => {
    const { currency } = useLedgerContext()

    const format = useMemo(
        () =>
            ({
                format: 'amount',
                currency,
            }) as const,
        [currency]
    )

    return <LedgerQuantity format={format} quantity={quantity} />
}
