import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'

import { LedgerMovement, LedgerMovementHistory, LedgerMovementLabelKey } from '../LedgerMovementHistory'

type LedgerInvestmentHistoryProps = {
    investments: LedgerInvestment[]
}

export const LedgerInvestmentHistory: FC<LedgerInvestmentHistoryProps> = ({ investments }) => {
    const { t } = useLedgerInvestmentHistoryTranslation()

    const label = useCallback((key: LedgerMovementLabelKey) => t(key), [t])
    const movements = useMemo(
        (): LedgerMovement[] =>
            investments.map(({ totalInvested, totalInvestedCash, totalInvestedEquity, ...other }) => ({
                total: totalInvested,
                totalCash: totalInvestedCash,
                totalEquity: totalInvestedEquity,
                ...other,
            })),
        [investments]
    )

    return <LedgerMovementHistory movements={movements} label={label} />
}

const useLedgerInvestmentHistoryTranslation = createUseTranslation({
    FR: {
        total: 'Montant total investi',
        totalCash: 'Montant total investi (cash)',
        totalEquity: 'Montant total investi (nature)',
    },
    EN: {
        total: 'Total invested amount',
        totalCash: 'Total invested amount (cash)',
        totalEquity: 'Total invested amount (equity)',
    },
})

type LedgerInvestment = {
    id: string
    occurredAt: DateTime
    totalInvested: number
    totalInvestedCash: number
    totalInvestedEquity: number
}
