import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form, FormInstance } from 'antd'
import { useCallback, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { ControlledForm, FormRules, useCommonRules, useConnectControlledForm } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { LedgerAccountType, LedgerHoldershipType, useCreateLedgerTransactionsMutation } from '../../../../../../data'
import { LedgerAccountSearch } from '../../../../../components'
import {
    TransactionContextForm,
    TransactionContextFormValues,
    TransferableAsset,
    TransferableAssetForm,
    unpackTransferableAsset,
    useDistinctLedgerAccountValidators,
    useTransactionFormTranslations,
} from '../components'

type FullOwnershipDonationFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, FullOwnershipDonationFormValues>
    refetchQueries?: InternalRefetchQueriesInclude
}

type LedgerAccount = {
    id: string
}

type FullOwnershipDonationFormValues = TransactionContextFormValues & {
    quantity?: number
    debitAccount?: LedgerAccount
    creditAccount?: LedgerAccount
    transfer?: TransferableAsset
}

export const FullOwnershipDonationForm: FC<FullOwnershipDonationFormProps> = ({ ledgerId, form, refetchQueries }) => {
    const [createLedgerTransactionsMutation, { loading }] = useCreateLedgerTransactionsMutation()

    const submit = useCallback(
        async (values: FullOwnershipDonationFormValues): Promise<string | undefined> => {
            const { occurredAt, comment, debitAccount, creditAccount, transfer, quantity } = values

            assert.defined(quantity)
            assert.defined(occurredAt)
            assert.defined(debitAccount)
            assert.defined(creditAccount)

            const debitAccountId = debitAccount.id
            const creditAccountId = creditAccount.id

            const { holdershipType, assetTypeId } = unpackTransferableAsset(transfer)

            if (holdershipType !== 'FULL_OWNERSHIP') {
                throw new Error('Can only donate FULL_OWNERSHIP assets')
            }

            return createLedgerTransactionsMutation({
                variables: {
                    ledgerId,
                    transactions: {
                        fullOwnershipDonations: [
                            {
                                sequence: 0,
                                occurredAt,
                                comment,
                                creditAccountId,
                                assetTypeId,
                                quantity,
                                debitAccountId,
                            },
                        ],
                    },
                },
                refetchQueries,
            }).then(transactions => transactions.data?.createLedgerTransactions[0]?.id)
        },
        [createLedgerTransactionsMutation, ledgerId, refetchQueries]
    )

    useConnectControlledForm(form, submit, loading)

    return <FullOwnershipDonationTransactionForm ledgerId={ledgerId} form={form.form} />
}

type FullOwnershipDonationTransactionFormProps = {
    ledgerId: string
    form: FormInstance<FullOwnershipDonationFormValues>
}

const FullOwnershipDonationTransactionForm: FC<FullOwnershipDonationTransactionFormProps> = ({ ledgerId, form }) => {
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()

    const [debitAccountValidator, creditAccountValidator] =
        useDistinctLedgerAccountValidators<FullOwnershipDonationFormValues>('debitAccount', 'creditAccount')

    const validation = useMemo<FormRules<FullOwnershipDonationFormValues>>(
        () => ({
            debitAccount: [...rules.required, debitAccountValidator],
            creditAccount: [...rules.required, creditAccountValidator],
        }),
        [creditAccountValidator, debitAccountValidator, rules.required]
    )

    const debitAccount = Form.useWatch('debitAccount', form)
    const transfer = Form.useWatch('transfer', form)

    const creditAccountDisabled = transfer === undefined
    const creditAccountTypes = useMemo(
        () => transfer?.assetType?.eligibleAccountTypes ?? [],
        [transfer?.assetType?.eligibleAccountTypes]
    )

    return (
        <Form form={form} layout="vertical">
            <TransactionContextForm ledgerId={ledgerId}>
                <Form.Item name="debitAccount" label={t('debitAccount')} rules={validation.debitAccount} hasFeedback>
                    <LedgerAccountSearch
                        ledgerAccountTypes={fullOwnershipDonationLedgerAccountType}
                        ledgerId={ledgerId}
                    />
                </Form.Item>
                <TransferableAssetForm
                    debitAccountId={debitAccount?.id}
                    form={form}
                    holdershipTypes={fullOwnershipDonationLedgerAccountTypeHoldershipTypes}
                />
                <Form.Item name="creditAccount" label={t('creditAccount')} rules={validation.creditAccount} hasFeedback>
                    <LedgerAccountSearch
                        ledgerAccountTypes={creditAccountTypes}
                        ledgerId={ledgerId}
                        disabled={creditAccountDisabled}
                    />
                </Form.Item>
            </TransactionContextForm>
        </Form>
    )
}

const fullOwnershipDonationLedgerAccountType: LedgerAccountType[] = ['PEA_PME', 'PEA', 'STANDARD']
const fullOwnershipDonationLedgerAccountTypeHoldershipTypes: LedgerHoldershipType[] = ['FULL_OWNERSHIP']
