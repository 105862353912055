import { ColumnGroupType } from 'antd/lib/table'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable, LinkButton } from '@publica/ui-web-components'

import { LedgerBalance, LedgerBeneficiary } from '../../types'
import { useShareTableColumns } from '../common'

type LedgerBeneficiaryTableProps = {
    ledger: {
        id: string
        balance?: LedgerBalance
    }
    ledgerGroup: {
        id: string
        beneficiaries: LedgerBeneficiary[]
    }
}

export const LedgerBeneficiaryTable: FC<LedgerBeneficiaryTableProps> = ({ ledgerGroup, ledger }) => {
    const { t } = useLedgerBeneficiaryTableTranslations()
    const beneficiaries = ledgerGroup.beneficiaries

    const shareTableColumns = useShareTableColumns<LedgerBeneficiary>(ledger)

    const loading = shareTableColumns.loading

    const columns = useMemo<(FilterColumnType<LedgerBeneficiary> | ColumnGroupType<LedgerBeneficiary>)[]>(
        () => [
            {
                title: t('legalEntity'),
                render: (_, beneficiary) => (
                    <LinkButton type="link" size="small" to={beneficiary.id}>
                        {beneficiary.legalEntity.name}
                    </LinkButton>
                ),
            },
            ...shareTableColumns.columns,
        ],
        [shareTableColumns, t]
    )

    return <FilterTable<LedgerBeneficiary> loading={loading} columns={columns} dataSource={beneficiaries} />
}

const useLedgerBeneficiaryTableTranslations = createUseTranslation({
    FR: {
        legalEntity: 'Entité légale',
    },
    EN: {
        legalEntity: 'Legal entity',
    },
})
