import { Col, Row } from 'antd'
import isNil from 'lodash/isNil'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { KnownLocale } from '@publica/locales'
import { renderValue } from '@publica/render'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

import { LedgerName } from '../../../../components'
import { LedgerContextControl } from './LedgerContextControl'

type LedgerHeaderProps = {
    ledger: {
        name: string
        company: MaybeCompany
    }
}

const useLedgerHeaderStyles = createUseStyles({
    description: {
        color: colors.grey6,
        marginTop: 5,
        fontWeight: 'normal',
    },
    controls: {
        fontWeight: 'normal',
    },
})

export const LedgerHeader: FC<LedgerHeaderProps> = ({ ledger }) => {
    const styles = useLedgerHeaderStyles()
    const description = useLedgerCompanyDescription(ledger)

    return (
        <Row justify="space-between">
            <Col>
                <div>
                    <LedgerName ledger={ledger} />
                </div>
                <div className={styles.description}>{description}</div>
            </Col>
            <Col>
                <div className={styles.controls}>
                    <LedgerContextControl />
                </div>
            </Col>
        </Row>
    )
}

const useLedgerCompanyDescriptionTranslations = createUseTranslation({
    FR: {
        company: `Société {{name}}`,
        headquarters: `dont le siège social est situé {{address}}`,
        registration: `enregistré au RCS de {{registrationLocality}} sous le numéro {{registrationNumber}}`,
        registrationWithoutLocality: `enregistré sous le numéro {{registrationNumber}}`,
    },
    EN: {
        company: `Company {{name}}`,
        headquarters: `whose headquarters are located at {{address}}`,
        registration: `registered in {{registrationLocality}} as company {{registrationNumber}}`,
        registrationWithoutLocality: `registered as company {{registrationNumber}}`,
    },
})

type LedgerForCompanyDescription = {
    name: string
    company: MaybeCompany
}

const useLedgerCompanyDescription = (ledger: LedgerForCompanyDescription) => {
    const { t } = useLedgerCompanyDescriptionTranslations()
    const locale = useCurrentLocale()

    return useMemo(() => {
        const parts: string[] = [t('company', { name: ledger.name })]

        const details = getCompanyDetails(ledger.company, locale)

        if (details.companyType) {
            parts.push(details.companyType)
        }

        if (details.address) {
            parts.push(t('headquarters', { address: details.address }))
        }

        if (details.registrationLocality) {
            parts.push(
                t('registration', {
                    registrationLocality: details.registrationLocality,
                    registrationNumber: details.registrationNumber,
                })
            )
        } else {
            parts.push(t('registrationWithoutLocality', { registrationNumber: details.registrationNumber }))
        }

        return parts.join(' ')
    }, [ledger.company, ledger.name, locale, t])
}

type MaybeCompany =
    | {
          __typename: 'LegalEntityCompany'
          identifier: string
          registrationNumber: string
          registrationLocality?: string | null
          companyType?: string | null
          address?: {
              address: string
              city: string
              postCode: string
              country: string
          } | null
      }
    | {
          __typename: 'LegalEntityIndividual'
          identifier: string
      }

type CompanyDetails = {
    registrationNumber: string
    registrationLocality?: string
    companyType?: string
    address?: string
}

const getCompanyDetails = (maybeCompany: MaybeCompany, locale: KnownLocale): CompanyDetails => {
    if (maybeCompany.__typename === 'LegalEntityIndividual') {
        throw new Error(`Expected legal entity to be of type company`)
    }

    const { registrationNumber, registrationLocality, companyType, address } = maybeCompany

    let renderedAddress: string | undefined

    if (!isNil(address)) {
        renderedAddress = renderValue({
            type: 'MapValue',
            mapValue: (['address', 'city', 'postCode', 'country'] as const).map(key => ({
                key,
                value: address[key],
            })),
            options: {
                subType: 'ADDRESS',
                locale,
            },
            context: 'web',
        })
    }

    return {
        registrationNumber,
        registrationLocality: registrationLocality ?? undefined,
        companyType: companyType ?? undefined,
        address: renderedAddress,
    }
}
