import { BankOutlined, EuroOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Tabs } from 'antd'
import isNil from 'lodash/isNil'
import { useEffect, useMemo } from 'react'
import { useMatch, useNavigate, useParams, useResolvedPath } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC, NotFoundError } from '@publica/ui-common-utils'
import { Tab, useTabs } from '@publica/ui-web-components'
import { assert } from '@publica/utils'

import { useGetLedgerIdentitySuspenseQuery } from '../../../../data'
import { LedgerContext, useNewLedgerContext } from '../../../context'
import { LedgerAccounts } from '../../ledgerAccounts'
import { LedgerAssetTypes } from '../../ledgerAssetTypes'
import { LedgerSummary } from '../../ledgerSummary'
import { LedgerTransactions } from '../../ledgerTransactions'
import { LedgerHeader } from './LedgerHeader'

export const ShowLedger: FC = () => {
    useRedirectFromRoot()

    const { ledgerId } = useParams()
    assert.defined(ledgerId, 'Undefined ledger ID')

    const { t } = useShowLedgerTranslation()
    const { data } = useGetLedgerIdentitySuspenseQuery({
        variables: {
            ledgerId,
        },
    })

    const ledger = data.ledger

    if (isNil(ledger)) {
        throw new NotFoundError(t('ledgerNotFound'))
    }

    const tabs = useTabs(
        useMemo<Tab[]>(
            () => [
                {
                    key: 'summary',
                    label: t('summary'),
                    content: <LedgerSummary ledger={ledger} />,
                    icon: <BankOutlined />,
                },
                {
                    key: 'accounts',
                    label: t('accounts'),
                    content: <LedgerAccounts ledger={ledger} />,
                    icon: <UserOutlined />,
                    matches: location => location.pathname.match(`${ledger.id}/accounts`) !== null,
                },
                {
                    key: 'transactions',
                    label: t('transactions'),
                    content: <LedgerTransactions ledger={ledger} />,
                    icon: <SwapOutlined />,
                },
                {
                    key: 'assetTypes',
                    label: t('assetTypes'),
                    content: <LedgerAssetTypes ledger={ledger} />,
                    icon: <EuroOutlined />,
                },
            ],
            [ledger, t]
        )
    )

    const ledgerContext = useNewLedgerContext(ledger.currency)
    const title = useMemo(() => <LedgerHeader ledger={ledger} />, [ledger])

    return (
        <LedgerContext.Provider value={ledgerContext}>
            <Card title={title}>
                <Tabs
                    defaultActiveKey="summary"
                    activeKey={tabs.activeTab}
                    onTabClick={tabs.onChange}
                    items={tabs.items}
                />
            </Card>
        </LedgerContext.Provider>
    )
}

const useShowLedgerTranslation = createUseTranslation({
    FR: {
        ledgerNotFound: 'Registre inconnu',
        summary: 'Synthèse Société',
        transactions: 'Transactions',
        accounts: 'Comptes',
        assetTypes: 'Actifs',
    },
    EN: {
        ledgerNotFound: 'Ledger not found',
        summary: 'Company Summary',
        transactions: 'Transactions',
        accounts: 'Accounts',
        assetTypes: 'Asset types',
    },
})

const useRedirectFromRoot = () => {
    const rootResolved = useResolvedPath('')
    const isRoot = useMatch({ path: rootResolved.pathname, end: true }) !== null

    const navigate = useNavigate()

    useEffect(() => {
        if (isRoot) {
            navigate('summary')
        }
    }, [isRoot, navigate])
}
