import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Divider, Form } from 'antd'
import { useCallback, useMemo } from 'react'

import { LocalizedString, availableLocaleTags } from '@publica/locales'
import { LedgerAccountTypeExcludingPledge, ledgerAccountTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { LocalizedStringInput, Select } from '@publica/ui-web-components'
import {
    ControlledForm,
    ControlledFormWithElement,
    FormRules,
    asyncValidator,
    useCommonRules,
    useControlledForm,
} from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { useCreateLedgerAssetTypeMutation, useGetLedgerAssetTypesLazyQuery } from '../../../../../data'
import { QuantityInput } from '../../../../components'

type NewLedgerAssetTypeFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, NewLedgerAssetTypeFormValues>
}

const NewLedgerAssetTypeForm: FC<NewLedgerAssetTypeFormProps> = ({ form, ledgerId }) => {
    const { t } = useNewLedgerAssetTypeTranslation()
    const locale = useCurrentLocale()
    const rules = useCommonRules()

    const [getLedgerAssetTypes] = useGetLedgerAssetTypesLazyQuery()

    const validationRules = useMemo<FormRules<NewLedgerAssetTypeFormValues>>(
        () => ({
            name: [
                ...rules.required,
                asyncValidator(async (name: NewLedgerAssetTypeFormValues['name']) => {
                    if (name === undefined) {
                        return
                    }

                    const existingAssetTypes = await getLedgerAssetTypes({
                        variables: {
                            ledgerId,
                        },
                        fetchPolicy: 'no-cache',
                    })

                    for (const assetType of existingAssetTypes.data?.ledgerAssetTypesForLedger ?? []) {
                        for (const locale of availableLocaleTags) {
                            const existingAssetTypeName = assetType.name[locale]?.trim().toLowerCase()
                            const newAssetTypeName = name[locale]?.trim().toLowerCase()
                            if (
                                existingAssetTypeName !== undefined &&
                                newAssetTypeName !== undefined &&
                                existingAssetTypeName === newAssetTypeName
                            ) {
                                throw new Error(t('alreadyExists'))
                            }
                        }
                    }
                }),
            ],
            parValue: rules.required,
            eligibleAccountTypes: [...rules.required],
        }),
        [getLedgerAssetTypes, ledgerId, rules.required, t]
    )

    const initialValues = useMemo<Partial<NewLedgerAssetTypeFormValues>>(
        () => ({
            eligibleAccountTypes: ['STANDARD'],
            parValue: 1,
        }),
        []
    )

    const options = useMemo(() => {
        const types = ['STANDARD', 'PEA', 'PEA_PME'] as const
        return types.map(type => ({
            value: type,
            label: ledgerAccountTypeLookup.labelForKeyAndLocale(type, locale),
        }))
    }, [locale])

    return (
        <Form form={form.form} layout="vertical" initialValues={initialValues}>
            <Form.Item name="name" label={t('name')} hasFeedback rules={validationRules.name}>
                <LocalizedStringInput path="name" />
            </Form.Item>
            <Divider />
            <Form.Item name="parValue" label={t('parValue')} hasFeedback rules={validationRules.parValue}>
                <QuantityInput />
            </Form.Item>
            <Form.Item
                name="eligibleAccountTypes"
                label={t('eligibleAccountTypes')}
                hasFeedback
                rules={validationRules.eligibleAccountTypes}
            >
                <Select mode="multiple" allowClear options={options} />
            </Form.Item>
        </Form>
    )
}

const useNewLedgerAssetTypeTranslation = createUseTranslation({
    FR: {
        name: `Nom de l'actif`,
        parValue: 'Valeur nominale',
        alreadyExists: `Il existe déjà un actif portant ce nom`,
        eligibleAccountTypes: `Comptes éligibles`,
    },
    EN: {
        name: 'Asset name',
        parValue: 'Par value',
        alreadyExists: 'There is already an asset type with this name',
        eligibleAccountTypes: 'Eligible account types',
    },
})

type NewLedgerAssetTypeFormValues = {
    name: LocalizedString | undefined
    parValue: number
    eligibleAccountTypes: LedgerAccountTypeExcludingPledge[] | undefined
}

type NewLedgerAssetTypeFormOptions = {
    refetchQueries?: InternalRefetchQueriesInclude
}

export const useNewLedgerAssetTypeForm = (
    ledgerId: string,
    options?: NewLedgerAssetTypeFormOptions
): ControlledFormWithElement<string | undefined, NewLedgerAssetTypeFormValues> => {
    const [createLedgerAssetType, { loading }] = useCreateLedgerAssetTypeMutation()

    const submit = useCallback(
        async (values: NewLedgerAssetTypeFormValues) => {
            const { name, parValue, eligibleAccountTypes } = values

            assert.defined(name)

            return createLedgerAssetType({
                variables: {
                    ledgerId,
                    assetType: {
                        name,
                        parValue,
                        eligibleAccountTypes: eligibleAccountTypes ?? [],
                    },
                },
                refetchQueries: options?.refetchQueries,
            }).then(({ data }) => data?.createLedgerAssetType.id)
        },
        [createLedgerAssetType, ledgerId, options?.refetchQueries]
    )

    return useControlledForm({
        render: form => <NewLedgerAssetTypeForm form={form} ledgerId={ledgerId} />,
        submit,
        loading,
    })
}
