import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form } from 'antd'
import isNil from 'lodash/isNil'
import { useCallback, useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import {
    ControlledForm,
    ControlledFormWithElement,
    FormRules,
    asyncValidator,
    useCommonRules,
    useControlledForm,
} from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { useCheckLedgerCompanyLazyQuery, useCreateLedgerMutation } from '../../../../data'
import { LegalEntitySearch } from '../../../components/LegalEntitySearch'

type NewLedgerFormProps = {
    form: ControlledForm<string | undefined, NewLedgerFormValues>
}

const NewLedgerForm: FC<NewLedgerFormProps> = ({ form }) => {
    const { t } = useNewLedgerFormTranslation()
    const [checkLedgerCompanyQuery] = useCheckLedgerCompanyLazyQuery()

    const rules = useCommonRules()

    const validationRules = useMemo<FormRules<NewLedgerFormValues>>(
        () => ({
            company: [
                ...rules.required,
                asyncValidator(async (company: NewLedgerFormValues['company']) => {
                    if (company === undefined) {
                        return
                    }

                    const { data } = await checkLedgerCompanyQuery({
                        variables: {
                            companyId: company.id,
                        },
                    })

                    if (!isNil(data?.ledgerByCompany?.id)) {
                        throw new Error(t('alreadyLedgerForCompany'))
                    }
                }),
            ],
        }),
        [checkLedgerCompanyQuery, rules.required, t]
    )

    return (
        <Form form={form.form} layout="vertical">
            <Form.Item
                name="company"
                label={t('company')}
                hasFeedback
                validateFirst={true}
                rules={validationRules.company}
            >
                <LegalEntitySearch type="COMPANY" />
            </Form.Item>
        </Form>
    )
}

const useNewLedgerFormTranslation = createUseTranslation({
    FR: {
        alreadyLedgerForCompany: `Il existe déjà un registre pour cette société`,
        company: 'Société',
    },
    EN: {
        alreadyLedgerForCompany: `There is already a ledger for this company`,
        company: 'Company',
    },
})

type NewLedgerFormValues = {
    company: { id: string } | undefined
}

type NewLedgerFormOptions = {
    refetchQueries?: InternalRefetchQueriesInclude
}

export const useNewLedgerForm = (
    options?: NewLedgerFormOptions
): ControlledFormWithElement<string | undefined, NewLedgerFormValues> => {
    const [createLedgerMutation, { loading }] = useCreateLedgerMutation()

    const submit = useCallback(
        async (values: NewLedgerFormValues) => {
            const { company } = values

            assert.defined(company)

            return createLedgerMutation({
                variables: {
                    ledger: {
                        companyId: company.id,
                    },
                },
                refetchQueries: options?.refetchQueries,
            }).then(({ data }) => data?.createLedger.id)
        },
        [createLedgerMutation, options?.refetchQueries]
    )

    return useControlledForm({
        render: form => <NewLedgerForm form={form} />,
        submit,
        loading,
    })
}
