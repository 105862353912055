import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form } from 'antd'
import { useCallback, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { ControlledForm, FormRules, useCommonRules, useConnectControlledForm } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import {
    LedgerAccountType,
    LedgerTransactionsCreateInput,
    useCreateLedgerTransactionsMutation,
} from '../../../../../../data'
import { QuantityInput } from '../../../../../components'
import {
    CreditOnlyTransactionForm,
    CreditOnlyTransactionFormValues,
    useTransactionFormTranslations,
} from '../components'

type SubscriptionFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, SubscriptionFormValues>
    refetchQueries?: InternalRefetchQueriesInclude
    type: 'SUBSCRIPTION_CASH' | 'SUBSCRIPTION_EQUITY'
}

type SubscriptionFormValues = CreditOnlyTransactionFormValues & {
    sharePremium?: number
    balancingPayment?: number
}

export const SubscriptionForm: FC<SubscriptionFormProps> = ({ ledgerId, form, refetchQueries, type }) => {
    const [createLedgerTransactionsMutation, { loading }] = useCreateLedgerTransactionsMutation()
    const { t } = useTransactionFormTranslations()
    const rules = useCommonRules()

    const validation: FormRules<SubscriptionFormValues> = useMemo(
        () => ({
            sharePremium: rules.required,
            balancingPayment: rules.required,
        }),
        [rules.required]
    )

    const submit = useCallback(
        async (values: SubscriptionFormValues): Promise<string | undefined> => {
            const { occurredAt, comment, account, assetType, quantity, sharePremium, balancingPayment } = values

            assert.defined(occurredAt)
            assert.defined(quantity)
            assert.defined(account)
            assert.defined(assetType)
            assert.defined(sharePremium)

            const accountId = account.id
            const assetTypeId = assetType.id

            const transactions: LedgerTransactionsCreateInput = {}

            switch (type) {
                case 'SUBSCRIPTION_CASH':
                    transactions.cashSubscriptions = [
                        {
                            occurredAt,
                            comment,
                            quantity,
                            accountId,
                            assetTypeId,
                            sequence: 0,
                            sharePremium,
                        },
                    ]
                    break

                case 'SUBSCRIPTION_EQUITY':
                    assert.defined(balancingPayment)
                    transactions.equitySubscriptions = [
                        {
                            occurredAt,
                            comment,
                            quantity,
                            accountId,
                            assetTypeId,
                            sequence: 0,
                            sharePremium,
                            balancingPayment,
                        },
                    ]
                    break
            }

            return createLedgerTransactionsMutation({
                variables: {
                    ledgerId,
                    transactions,
                },
                refetchQueries,
            }).then(transactions => transactions.data?.createLedgerTransactions[0]?.id)
        },
        [createLedgerTransactionsMutation, ledgerId, refetchQueries, type]
    )

    useConnectControlledForm(form, submit, loading)

    return (
        <CreditOnlyTransactionForm
            ledgerAccountTypes={subscriptionLedgerAccountTypes}
            ledgerId={ledgerId}
            form={form.form}
            initialValues={initialValues}
        >
            <Form.Item
                name="sharePremium"
                label={t('sharePremium')}
                required
                hasFeedback
                rules={validation.sharePremium}
            >
                <QuantityInput />
            </Form.Item>
            {type === 'SUBSCRIPTION_EQUITY' ? (
                <Form.Item
                    name="balancingPayment"
                    label={t('balancingPayment')}
                    required
                    hasFeedback
                    rules={validation.balancingPayment}
                >
                    <QuantityInput />
                </Form.Item>
            ) : null}
        </CreditOnlyTransactionForm>
    )
}

const initialValues: Partial<SubscriptionFormValues> = {
    balancingPayment: 0,
    sharePremium: 1,
}

const subscriptionLedgerAccountTypes: LedgerAccountType[] = ['PEA', 'PEA_PME', 'STANDARD']
