import { Rule } from 'antd/es/form'
import isString from 'lodash/isString'
import { useMemo } from 'react'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { asyncValidator } from '@publica/ui-web-utils'

import { LedgerAccountType } from '../../../../../../data'
import { createDistinctObjectValidator } from '../../../../../lib'

export const useTransactionFormTranslations = createUseTranslation({
    FR: {
        creditAccount: `Compte à créditer`,
        creditQuantity: 'Quantité à créditer',
        debitAccount: `Compte à débiter`,
        debitQuantity: 'Quantité à débiter',
        assetType: 'Actif',
        quantity: 'Quantité',
        account: 'Compte',
        accountsMustDiffer: 'Les comptes doivent être différents',
        usufructAccount: 'Compte usufruiter',
        bareOwnershipAccount: 'Compte nue-propriété',
        insufficientBalance: 'La quantité est supérieur à la détention actuelle',
        sharePremium: `Prime d'émission unitaire`,
        balancingPayment: 'Soulte',
        acquisitionProfit: `Gain d'acquisition`,
        balance: 'Solde',
        mustBeStrictlyPositive: `Doit être supérieur à 0`,
        unitValue: `Valeur unitaire`,
    },
    EN: {
        creditAccount: 'Account to credit',
        creditQuantity: 'Amount to credit',
        debitAccount: `Account to debit`,
        debitQuantity: 'Amount to debit',
        assetType: 'Asset',
        quantity: 'Quantity',
        account: 'Account',
        accountsMustDiffer: 'Accounts must be different',
        usufructAccount: 'Usufruct account',
        bareOwnershipAccount: 'Bare ownership account',
        insufficientBalance: 'The amount is greater than the current balance',
        sharePremium: 'Unit share premium',
        balancingPayment: 'Balancing payment',
        acquisitionProfit: 'Acquisition profit',
        balance: 'Balance',
        mustBeStrictlyPositive: `Must be strictly positive`,
        unitValue: `Unit value`,
    },
})

export const useQuantityValidator = () => {
    const { t } = useTransactionFormTranslations()

    return useMemo(
        () =>
            asyncValidator(async (quantity: number) => {
                if (quantity <= 0) {
                    throw new Error(t('mustBeStrictlyPositive'))
                }
            }),
        [t]
    )
}

type LedgerAccount = { id: string; type: LedgerAccountType }
type LedgerAssetType = { id: string; eligibleAccountTypes: LedgerAccountType[] }

export const useLedgerAssetTypeEligibilityValidator = (accountField: string) => {
    const { t } = useLedgerAssetTypeEligibilityValidatorTranslation()

    return useMemo<Rule>(
        () =>
            ({ getFieldValue }) => ({
                validator: async (_, assetType: LedgerAssetType | undefined) => {
                    const account = getFieldValue(accountField) as LedgerAccount | undefined

                    if (
                        assetType !== undefined &&
                        account !== undefined &&
                        !assetType.eligibleAccountTypes.includes(account.type)
                    ) {
                        throw new Error(t('assetNotEligible'))
                    }
                },
            }),
        [accountField, t]
    )
}

const useLedgerAssetTypeEligibilityValidatorTranslation = createUseTranslation({
    FR: {
        assetNotEligible: `Cet actif n'est pas éligible pour ce type de compte`,
    },
    EN: {
        assetNotEligible: 'This asset type is not elible for this account type',
    },
})

export const useDistinctLedgerAccountValidators = <
    V extends Record<string, unknown> = Record<string, unknown>,
    K extends keyof V = keyof V,
>(
    aFieldKey: K,
    bFieldKey: K
) => {
    const { t } = useDistinctLedgerAccountValidatorTranslation()

    if (!(isString(aFieldKey) && isString(bFieldKey))) {
        throw new Error(`Keys must be strings`)
    }

    return useMemo<[Rule, Rule]>(
        () => [
            // NOTE: the field keys are _supposed_ to be inverted
            createDistinctObjectValidator(bFieldKey, t('accountsMustDiffer')),
            createDistinctObjectValidator(aFieldKey, t('accountsMustDiffer')),
        ],
        [aFieldKey, bFieldKey, t]
    )
}

const useDistinctLedgerAccountValidatorTranslation = createUseTranslation({
    FR: {
        accountsMustDiffer: 'Les comptes doivent être différents',
    },
    EN: {
        accountsMustDiffer: 'Accounts must be different',
    },
})
