import { ColumnGroupType } from 'antd/lib/table'
import { ExpandableConfig } from 'antd/lib/table/interface'
import { useMemo } from 'react'

import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'
import { usePollingRate } from '@publica/ui-web-state'

import { useGetLedgerGroupsQuery } from '../../../../../data'
import { useLedgerContext } from '../../../../context'
import { LedgerGroup } from '../../types'
import { LedgerBeneficiaryTable } from '../LedgerBeneficiaryTable'
import { useShareTableColumns } from '../common'

type LedgerGroupsTableProps = {
    ledger: {
        id: string
    }
}

export const LedgerGroupsTable: FC<LedgerGroupsTableProps> = props => {
    const { t } = useLedgerGroupsTableTranslations()
    const resolveLocalizedString = useLocalizedStringResolver()

    const { cursor } = useLedgerContext()

    const getLedgerGroups = useGetLedgerGroupsQuery({
        variables: {
            ledgerId: props.ledger.id,
            cursor,
        },
        pollInterval: usePollingRate(),
    })

    const groups = useMemo<LedgerGroup[]>(
        () => getLedgerGroups.data?.ledger?.groups ?? [],
        [getLedgerGroups.data?.ledger?.groups]
    )
    const ledger = useMemo(
        () => ({
            ...(getLedgerGroups.data?.ledger ?? {}),
            id: props.ledger.id,
        }),
        [getLedgerGroups.data?.ledger, props.ledger.id]
    )

    const shareTableColumns = useShareTableColumns<LedgerGroup>(ledger)

    const loading = getLedgerGroups.loading || shareTableColumns.loading

    const columns = useMemo<(FilterColumnType<LedgerGroup> | ColumnGroupType<LedgerGroup>)[]>(
        () => [
            {
                title: t('group'),
                render: (_, group) => resolveLocalizedString(group.name),
            },
            {
                title: t('memberCount'),
                align: 'end',
                render: (_, group) => group.beneficiaries.length,
            },
            ...shareTableColumns.columns,
        ],
        [resolveLocalizedString, shareTableColumns, t]
    )

    const expandable = useMemo<ExpandableConfig<LedgerGroup>>(
        () => ({
            expandedRowRender: ledgerGroup => <LedgerBeneficiaryTable ledger={ledger} ledgerGroup={ledgerGroup} />,
        }),
        [ledger]
    )

    return <FilterTable<LedgerGroup> columns={columns} loading={loading} dataSource={groups} expandable={expandable} />
}

const useLedgerGroupsTableTranslations = createUseTranslation({
    FR: {
        group: 'Groupe',
        memberCount: 'Membres',
    },
    EN: {
        group: 'Group',
        memberCount: 'Members',
    },
})
