import sortBy from 'lodash/sortBy'
import { useCallback, useMemo } from 'react'

import { ledgerHoldershipTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { FilterColumnType, FilterTable } from '@publica/ui-web-components'

import { LedgerTransactionType } from '../../../../data'
import { LedgerAccountLabel } from '../../LedgerAccountLabel'
import { LedgerCurrencyAmount } from '../../LedgerCurrencyAmount'
import { LedgerQuantity } from '../../LedgerQuantity'
import { LedgerEntry, LedgerTransaction } from '../types'

type LedgerEntryTableProps = {
    transaction: LedgerTransaction
}

export const LedgerEntryTable: FC<LedgerEntryTableProps> = ({ transaction }) => {
    const { t } = useLedgerEntryTableTranslation()
    const locale = useCurrentLocale()
    const resolveLocalizedString = useLocalizedStringResolver()

    const columns = useMemo<FilterColumnType<LedgerEntry>[]>(
        () => [
            {
                title: t('account'),
                render: (_, entry) => <LedgerAccountLabel account={entry.account} />,
            },
            {
                title: t('assetType'),
                render: (_, entry) => resolveLocalizedString(entry.assetType.name),
            },
            {
                title: t('holdership'),
                render: (_, entry) => ledgerHoldershipTypeLookup.labelForKeyAndLocale(entry.holdershipType, locale),
            },
            {
                title: t('amount'),
                align: 'end',
                render: (_, entry) => <LedgerQuantity quantity={entry.quantity} />,
            },
            ...properties
                .filter(property => propertyRelevance[property].includes(transaction.type))
                .map(
                    (property): FilterColumnType<LedgerEntry> => ({
                        title: t(property),
                        align: 'end',
                        render: (_, entry) => <LedgerCurrencyAmount quantity={entry[property]} />,
                    })
                ),
        ],
        [t, resolveLocalizedString, locale, transaction.type]
    )

    const sortedStatements = useMemo(() => sortBy(transaction.entries, entry => entry.sequence), [transaction.entries])
    const title = useCallback(() => t('entries'), [t])

    return <FilterTable<LedgerEntry> columns={columns} dataSource={sortedStatements} title={title} />
}

const useLedgerEntryTableTranslation = createUseTranslation({
    FR: {
        account: 'Compte',
        assetType: 'Actif',
        amount: 'Nombre de titres',
        holdership: 'Détention',
        entries: 'Écritures',
        parValue: 'Valeur nominale',
        sharePremium: `Prime d'émission`,
        unitValue: `Valeur unitaire`,
        balancingPayment: 'Soulte',
        acquisitionProfit: `Montant du gain d'acquisition`,
    },
    EN: {
        account: 'Account',
        assetType: 'Asset',
        amount: 'Number of shares',
        holdership: 'Holdership',
        entries: 'Entries',
        parValue: 'Par value',
        sharePremium: 'Share premium',
        unitValue: 'Unit value',
        balancingPayment: 'Balancing payment',
        acquisitionProfit: 'Acquisition profit',
    },
})

const properties = ['sharePremium', 'unitValue', 'balancingPayment', 'acquisitionProfit'] as const

type Property = (typeof properties)[number]

const propertyRelevance: Record<Property, LedgerTransactionType[]> = {
    sharePremium: ['SUBSCRIPTION_CASH', 'SUBSCRIPTION_EQUITY', 'EMISSION'],
    unitValue: [
        'SUBSCRIPTION_CASH',
        'SUBSCRIPTION_EQUITY',
        'EMISSION',
        'DONATION_DISMEMBERMENT',
        'DONATION_FULL_OWNERSHIP',
        'TRANSFER_CASH',
        'TRANSFER_EQUITY',
    ],
    balancingPayment: ['SUBSCRIPTION_EQUITY'],
    acquisitionProfit: ['EMISSION'],
}
