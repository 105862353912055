import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ModalForm, icons } from '@publica/ui-web-components'

import { GetLedgersDocument } from '../../../../../data'
import { useNewLedgerForm } from '../../NewLedgerForm'

export const NewLedgerControl: FC = () => {
    const form = useNewLedgerForm({
        refetchQueries: [GetLedgersDocument],
    })

    const { t } = useLedgerControlsTranslation()
    const navigate = useNavigate()

    useEffect(() => {
        form.control.events.on('submit', async ({ result }) => {
            if (result.validated && result.result !== undefined) {
                navigate(result.result)
            }
        })
    }, [form.control.events, navigate])

    return <ModalForm form={form} icon={icons.Add} title={t('createLedger')} />
}

const useLedgerControlsTranslation = createUseTranslation({
    FR: {
        createLedger: 'Créer un registre',
    },
    EN: {
        createLedger: 'Create a ledger',
    },
})
