import { InputNumber, InputNumberProps } from 'antd'
import { CSSProperties } from 'react'

type QuantityInputProps = InputNumberProps

export const QuantityInput = ({ min = 0, ...props }: QuantityInputProps) => (
    <InputNumber style={styles} min={min} {...props} />
)

const styles: CSSProperties = {
    width: '100%',
}
