import isArray from 'lodash/isArray'
import { useCallback, useMemo } from 'react'

import { LocalizedString } from '@publica/locales'
import { useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { Select } from '@publica/ui-web-components'
import { buildIdMap } from '@publica/utils'

import { LedgerAccountType, useGetLedgerAssetTypesSuspenseQuery } from '../../../data'

type LedgerAssetTypeSelectProps = {
    ledgerId: string
    onChange?: (value: LedgerAssetType | undefined) => void
    value?: LedgerAssetType
    disabled?: boolean
    accountType?: LedgerAccountType
}

type LedgerAssetType = {
    id: string
    name: LocalizedString
    eligibleAccountTypes: LedgerAccountType[]
}

export const LedgerAssetTypeSelect: FC<LedgerAssetTypeSelectProps> = ({
    ledgerId,
    onChange,
    disabled,
    accountType,
    value,
}) => {
    const resolveLocalizedString = useLocalizedStringResolver()

    const { data } = useGetLedgerAssetTypesSuspenseQuery({
        variables: {
            ledgerId,
        },
    })

    const ledgerAssetTypeById = useMemo(
        () => buildIdMap(data.ledgerAssetTypesForLedger),
        [data.ledgerAssetTypesForLedger]
    )

    const options = useMemo(
        () =>
            data.ledgerAssetTypesForLedger
                .filter(assetType =>
                    accountType === undefined ? true : assetType.eligibleAccountTypes.includes(accountType)
                )
                .map(assetType => ({
                    label: resolveLocalizedString(assetType.name),
                    value: assetType.id,
                })),
        [accountType, data.ledgerAssetTypesForLedger, resolveLocalizedString]
    )

    const onSelectAssetType = useCallback(
        (_: unknown, option: OptionType | OptionType[] | undefined) => {
            if (option === undefined) {
                onChange?.(undefined)
            } else if (!isArray(option)) {
                const ledgerAssetType = ledgerAssetTypeById[option.value]
                onChange?.(ledgerAssetType)
            }
        },
        [ledgerAssetTypeById, onChange]
    )

    const selectedValue = value?.id

    return <Select options={options} onChange={onSelectAssetType} disabled={disabled} value={selectedValue} />
}

type OptionType = {
    label: string
    value: string
}
