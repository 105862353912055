import isArray from 'lodash/isArray'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ledgerAccountTypeLookup } from '@publica/lookups'
import { createUseTranslation, useCurrentLocale } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { RemoteSelect, Select, VerticalSpacer } from '@publica/ui-web-components'

import { LedgerAccountType, useGetLedgerBeneficiariesByQueryLazyQuery } from '../../../data'

type LedgerAccount = {
    id: string
    type: LedgerAccountType
}

type LedgerAccountSearchProps = {
    onChange?: (value: LedgerAccount | undefined) => void
    value?: LedgerAccount
    ledgerId: string
    ledgerAccountTypes?: LedgerAccountType[]
    disabled?: boolean
}

export const LedgerAccountSearch: FC<LedgerAccountSearchProps> = ({
    ledgerId,
    value,
    onChange,
    ledgerAccountTypes,
    disabled,
}) => {
    const [getLedgerBeneficiariesByQueryLazyQuery, { loading }] = useGetLedgerBeneficiariesByQueryLazyQuery()
    const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccount[]>([])
    const { t } = useLedgerAccountSearchTranslation()
    const locale = useCurrentLocale()

    const search = useCallback<LedgerBeneficiaryAccountSearch>(
        async (term: string) =>
            getLedgerBeneficiariesByQueryLazyQuery({
                variables: {
                    name: term,
                    ledgerId,
                },
                fetchPolicy: 'no-cache',
            }).then(({ data }) =>
                (data?.ledgerBeneficiariesByQuery ?? []).map(
                    (ledgerBeneficiary): LedgerBeneficiaryAccountSelectOption => ({
                        label: ledgerBeneficiary.legalEntity.name,
                        value: ledgerBeneficiary.id,
                        ledgerAccounts:
                            ledgerAccountTypes === undefined
                                ? ledgerBeneficiary.accounts
                                : ledgerBeneficiary.accounts.filter(account =>
                                      ledgerAccountTypes.includes(account.type)
                                  ),
                    })
                )
            ),
        [getLedgerBeneficiariesByQueryLazyQuery, ledgerAccountTypes, ledgerId]
    )

    const [selectedAccount, setSelectedAccount] = useState<LedgerAccountSelectOption | null>(null)

    const onChangeLedgerBeneficiary = useCallback<
        (
            value: unknown,
            option: LedgerBeneficiaryAccountSelectOption | LedgerBeneficiaryAccountSelectOption[] | undefined
        ) => void
    >((_, option) => {
        setSelectedAccount(null)
        if (option === undefined) {
            setLedgerAccounts([])
        } else if (!isArray(option)) {
            setLedgerAccounts(option.ledgerAccounts)
        }
    }, [])

    const onChangeLedgerAccount = useCallback<
        (value: unknown, option: LedgerAccountSelectOption | LedgerAccountSelectOption[] | undefined) => void
    >((_, option) => {
        if (option === undefined) {
            setSelectedAccount(null)
        } else if (!isArray(option)) {
            setSelectedAccount(option)
        }
    }, [])

    useEffect(() => {
        if (value?.id === selectedAccount?.ledgerAccount.id) {
            return
        }

        onChange?.(selectedAccount?.ledgerAccount)
    }, [onChange, selectedAccount, selectedAccount?.ledgerAccount, value, value?.id])

    const accountSelectDisabled = ledgerAccounts.length === 0 || disabled

    const accountOptions = useMemo(
        (): LedgerAccountSelectOption[] =>
            ledgerAccounts.map(ledgerAccount => ({
                label: ledgerAccountTypeLookup.labelForKeyAndLocale(ledgerAccount.type, locale),
                value: ledgerAccount.id,
                ledgerAccount,
            })),
        [ledgerAccounts, locale]
    )

    return (
        <VerticalSpacer size={5}>
            <RemoteSelect<LedgerBeneficiaryAccountSelectOption>
                fetchData={search}
                loading={loading}
                onChange={onChangeLedgerBeneficiary}
                placeholder={t('ledgerBeneficiary')}
                disabled={disabled}
            />
            <Select
                disabled={accountSelectDisabled}
                options={accountOptions}
                placeholder={t('ledgerAccount')}
                onChange={onChangeLedgerAccount}
                value={selectedAccount?.value}
            />
        </VerticalSpacer>
    )
}

type LedgerBeneficiaryAccountSelectOption = {
    label: string
    value: string
    ledgerAccounts: LedgerAccount[]
}

type LedgerAccountSelectOption = {
    label: string
    value: string
    ledgerAccount: LedgerAccount
}

type LedgerBeneficiaryAccountSearch = (term: string) => Promise<LedgerBeneficiaryAccountSelectOption[]>

const useLedgerAccountSearchTranslation = createUseTranslation({
    EN: {
        ledgerBeneficiary: 'Beneficiary',
        ledgerAccount: 'Account',
    },
    FR: {
        ledgerAccount: 'Compte',
        ledgerBeneficiary: 'Bénéficiaire',
    },
})
