import { InternalRefetchQueriesInclude } from '@apollo/client'
import { Form } from 'antd'
import { useCallback, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { ControlledForm, FormRules, useCommonRules, useConnectControlledForm } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { LedgerAccountType, useCreateLedgerTransactionsMutation } from '../../../../../../data'
import { QuantityInput } from '../../../../../components'
import {
    CreditOnlyTransactionForm,
    CreditOnlyTransactionFormValues,
    useTransactionFormTranslations,
} from '../components'

type EmissionFormProps = {
    ledgerId: string
    form: ControlledForm<string | undefined, EmissionFormValues>
    refetchQueries?: InternalRefetchQueriesInclude
}

type EmissionFormValues = CreditOnlyTransactionFormValues & { sharePremium?: number; acquisitionProfit?: number }

export const EmissionForm: FC<EmissionFormProps> = ({ ledgerId, form, refetchQueries }) => {
    const [createLedgerTransactionsMutation, { loading }] = useCreateLedgerTransactionsMutation()
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()

    const validation: FormRules<EmissionFormValues> = useMemo(
        () => ({
            sharePremium: rules.required,
            acquisitionProfit: rules.required,
        }),
        [rules.required]
    )

    const submit = useCallback(
        async (values: EmissionFormValues): Promise<string | undefined> => {
            const { occurredAt, comment, account, assetType, quantity, sharePremium, acquisitionProfit } = values

            assert.defined(occurredAt)
            assert.defined(account)
            assert.defined(assetType)
            assert.defined(quantity)
            assert.defined(sharePremium)
            assert.defined(acquisitionProfit)

            const accountId = account.id
            const assetTypeId = assetType.id

            return createLedgerTransactionsMutation({
                variables: {
                    ledgerId,
                    transactions: {
                        emissions: [
                            {
                                sequence: 0,
                                comment,
                                occurredAt,
                                quantity,
                                accountId,
                                assetTypeId,
                                sharePremium,
                                acquisitionProfit,
                            },
                        ],
                    },
                },
                refetchQueries,
            }).then(({ data }) => data?.createLedgerTransactions[0]?.id)
        },
        [createLedgerTransactionsMutation, ledgerId, refetchQueries]
    )

    useConnectControlledForm(form, submit, loading)

    return (
        <CreditOnlyTransactionForm
            ledgerAccountTypes={emissionLedgerAccountTypes}
            ledgerId={ledgerId}
            form={form.form}
            initialValues={initialValues}
        >
            <Form.Item
                name="acquisitionProfit"
                label={t('acquisitionProfit')}
                required
                hasFeedback
                rules={validation.acquisitionProfit}
            >
                <QuantityInput />
            </Form.Item>
            <Form.Item
                name="sharePremium"
                label={t('sharePremium')}
                required
                hasFeedback
                rules={validation.sharePremium}
            >
                <QuantityInput />
            </Form.Item>
        </CreditOnlyTransactionForm>
    )
}

const initialValues: Partial<EmissionFormValues> = {
    sharePremium: 0,
    acquisitionProfit: 0,
}

const emissionLedgerAccountTypes: LedgerAccountType[] = ['PEA', 'PEA_PME', 'STANDARD']
