import { Suspense, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'

import { LedgerAccountsIndex } from './LedgerAccountsIndex'
import { ShowLedgerBeneficiary } from './ShowLedgerBeneficiary'

type LedgerAccountsProps = {
    ledger: {
        id: string
    }
}

export const LedgerAccounts: FC<LedgerAccountsProps> = ({ ledger }) => {
    const ledgerAccountsIndex = useMemo(() => <LedgerAccountsIndex ledger={ledger} />, [ledger])
    const showLedgerBeneficiary = useMemo(() => <ShowLedgerBeneficiary ledger={ledger} />, [ledger])

    return (
        <Suspense>
            <Routes>
                <Route path="accounts" element={ledgerAccountsIndex} />
                <Route path="accounts/:ledgerBeneficiaryId" element={showLedgerBeneficiary} />
            </Routes>
        </Suspense>
    )
}
