import { Form, FormInstance } from 'antd'
import { useEffect, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { FormRules, useCommonRules } from '@publica/ui-web-utils'

import { LedgerAccountType } from '../../../../../../../data'
import { LedgerAccountSearch, LedgerAssetTypeSelect, QuantityInput } from '../../../../../../components'
import { TransactionContextForm, TransactionContextFormValues } from '../TransactionContextForm'
import { useLedgerAssetTypeEligibilityValidator, useQuantityValidator, useTransactionFormTranslations } from '../common'

type CreditOnlyTransactionFormProps = {
    ledgerId: string
    form: FormInstance<CreditOnlyTransactionFormValues>
    ledgerAccountTypes?: LedgerAccountType[]
    initialValues?: Record<string, unknown>
}

type LedgerAccount = { id: string; type: LedgerAccountType }
type LedgerAssetType = { id: string; eligibleAccountTypes: LedgerAccountType[] }

export type CreditOnlyTransactionFormValues = {
    quantity: number | undefined
    account: LedgerAccount | undefined
    assetType: LedgerAssetType | undefined
} & TransactionContextFormValues

export const CreditOnlyTransactionForm: FC<CreditOnlyTransactionFormProps> = ({
    ledgerId,
    form,
    ledgerAccountTypes,
    children,
    initialValues,
}) => {
    const rules = useCommonRules()
    const { t } = useTransactionFormTranslations()
    const assetTypeValidator = useLedgerAssetTypeEligibilityValidator('creditAccount')
    const quantityValidator = useQuantityValidator()

    const validation: FormRules<CreditOnlyTransactionFormValues> = useMemo(
        () => ({
            account: rules.required,
            quantity: [...rules.required, quantityValidator],
            assetType: [...rules.required, assetTypeValidator],
        }),
        [assetTypeValidator, quantityValidator, rules.required]
    )

    const assetType = Form.useWatch('assetType', form)
    const account = Form.useWatch('account', form)

    useEffect(() => {
        if (assetType !== undefined) {
            void form.validateFields(['assetType'])
        }
    }, [assetType, account, form])

    const assetTypeSelectDisabled = account === undefined

    return (
        <Form form={form} layout="vertical" initialValues={initialValues}>
            <TransactionContextForm ledgerId={ledgerId}>
                <Form.Item name="account" label={t('creditAccount')} hasFeedback rules={validation.account}>
                    <LedgerAccountSearch ledgerAccountTypes={ledgerAccountTypes} ledgerId={ledgerId} />
                </Form.Item>
                <Form.Item name="quantity" label={t('creditQuantity')} hasFeedback rules={validation.quantity}>
                    <QuantityInput />
                </Form.Item>
                <Form.Item name="assetType" label={t('assetType')} hasFeedback rules={validation.assetType}>
                    <LedgerAssetTypeSelect
                        accountType={account?.type}
                        ledgerId={ledgerId}
                        disabled={assetTypeSelectDisabled}
                    />
                </Form.Item>
                {children}
            </TransactionContextForm>
        </Form>
    )
}
